import React, { useState } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { TextField, InputAdornment } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import LoadingIcon from '../Utils/LoadingIcon';
import { api } from '../../services/api';
import DefaultModal from '../Utils/DefaultModal';
import CodproModal from './codproModal';
import ColorButton from '../Buttons/ColorButton';
import toast from 'react-hot-toast';
import { Box } from '@mui/material';
import { altercam, addcam } from './functionsCore';
var timeout = 0

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#E0E3E7',
            '--TextField-brandBorderHoverColor': '#B2BAC2',
            '--TextField-brandBorderFocusedColor': '#6F7E8C',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    
     
    },
  });

const SearchCodpro = ({changeList,checkTable,state,setState,type,data}) => {
    const outerTheme = useTheme();

    const [selectedCodpro,setCodpro] = useState()
    const [loading,setLoading] = useState(false)
    const [modalOpen,setModalOpen] = useState(false)
    const [searchValue,setSearchValue] = useState()
   
    const getCodpro = async (codpro) => {
        setLoading(true)
          const body = {
            codpro: codpro,
            branch:state.branch
          }
          const res = await api.post(`/codprowhere/`,body)
        setLoading(false)
        if (res.status === 200) {
            const codpro_data = res.data

            const check = checkTable(codpro_data)
         
            if(check === true){
              toast.error('Já existe este codpro no caminhão 1 !')
              setSearchValue("")
              return
            }

            if(codpro_data.length === 0){
              toast.error('Nenhum registro encontrado!')
            }else{
              setModalOpen(true)
              codpro_data.forEach(each => {
                each.cargo = 0;
                each.checked = "false"
              });
              setCodpro(codpro_data)
            }
            
            return
        }
    }

    const closeModalStart = () => {
        setModalOpen(false)
        setSearchValue("")
    }

    const setGetCodpro = (codpro) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => { getCodpro(codpro) }, 1000)
    }

    function isEmpty(str) {
       if(str.trim().length === 0){
          return false
       }else{
          return true
       }
       
    }
      
    const changeInput = (event) => {

        if(isEmpty(event.target.value)){
          setGetCodpro(event.target.value)
        }
        setSearchValue(event.target.value)
    }


    const createAddAlterSugestion = (addList,alterList,lines) => {

      for (let row of addList) {

        addcam(state.sugestions,row.codpro,row.cargo,'table1',row)
 
      }

      for (let row of alterList) {
        let info = lines.filter(each => each.codpro === row.codpro)[0]
        const sum = parseInt(row.cargo) + parseInt(info.cargo)
        altercam(state.sugestions,row.codpro,sum,'table1',row)
      }
      
  }

    const addAlterNewCodpro = () => {
      const filteredList = selectedCodpro.filter(each => parseInt(each.cargo) !== 0)
      const lines = data.filter(each => each['table1'])[0]['table1'].lines
      const codproList = lines.map(each => each['codpro'])
      const addList = filteredList.filter(each => codproList.includes(each['codpro']) !== true)
      const alterList = filteredList.filter(each => codproList.includes(each['codpro']) === true)
  
      if(type !== 'admin'){
        createAddAlterSugestion(addList,alterList,lines)
      }


      let newList = []
      for (const each of addList){
        newList.push(each)
      }

      for (const item of alterList){
        let info = lines.filter(each => each.codpro === item.codpro)[0]
        const sum = parseInt(item.cargo) + parseInt(info.cargo)
        data.filter(each => each['table1'])[0]['table1'].lines.filter(each => each.codpro === item.codpro)[0].cargo = sum
      }

      const consolidatedList = [...lines,...newList]
      data.filter(each => each['table1'])[0]['table1'].lines = consolidatedList

  
      if(type !== 'admin'){
        setState({data:data})
      }else{
        setState({consolidatedStep4:data})
      }
      setState({changedTables:!state.changedTables})

      setSearchValue("")
      setModalOpen(false)
    }

    const ConfirmButton = () => {
        return(
            <Box>
                <ColorButton  className='realpadding' onClick={addAlterNewCodpro}>Adicionar</ColorButton>
            </Box>
        )
    }

    return(
        <>
         
         <DefaultModal dialogProps={{maxWidth:"xl"}} open={modalOpen} handleClose={closeModalStart} action={<ConfirmButton/>} content={<CodproModal state={state} data={selectedCodpro} />}
          title={' Pesquisa '} />

            
        <ThemeProvider theme={customTheme(outerTheme)}>
        <Box>
          <Box>
            Adicionar produto:
          </Box>
              <TextField 
                      sx={{width:'25rem'}}
                      placeholder="Busca por código/descrição"
                      value={searchValue}
                      size='small'
                      onChange={changeInput}
                      InputProps={{
                          endAdornment: 
                              <LoadingIcon loading={loading}/>
                              ,
                              startAdornment: (
                              <InputAdornment position="start">
                                  <SearchIcon />
                              </InputAdornment>
                              ),
                          }}
              />

        </Box>
        </ThemeProvider>
     
        </>
    )
}

export default SearchCodpro;