
import React, {  } from 'react'

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import SendBoxSearch from './sendboxSearch';

const CodproModal = ({data,state}) => {
    
    const columns = [
    
        {
            field: 'aro',
            headerName: 'Aro',
            flex: 0.2,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 35px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'medida',
            headerName: 'Medida',
            flex: 0.2,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 35px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'codpro',
            headerName: 'Codpro',
            flex: 0.2,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 35px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'descr',
            headerName: 'Descrição',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: `est${state.branch}`,
            headerName: `Est ${state.branch.toUpperCase()}`,
            flex: 0.4,
            renderCell: ({value}) => (
              <Box className='est-tables' sx={{padding:'0px 109px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: `reserv${state.branch}`,
            headerName: `Reserv ${state.branch.toUpperCase()}`,
            flex: 0.4,
            renderCell: ({value}) => (
              <Box className='ven-tables' sx={{padding:'0px 109px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
        },
        {
          field: `dispbks`,
          headerName: `BKS Disp`,
          flex: 0.4,
          renderCell: ({value}) => (
            <Box className='dep-bks' sx={{padding:'0px 109px',fontWeight:'bold'}}>
             {value}
            </Box>
          )
      },
      {
        field: 'cargo',
        headerName: 'Envio',
        renderCell: (params) => (
            <Box sx={{padding:'0px 10px'}}>
              <SendBoxSearch row={params.row}/>
            </Box>
      )}

    ]

    return(
    <>
        <Box sx={{width:'100%'}} >
            <br/>
                <DataGrid
                        rowHeight={30}
                        className='alterned_grid'
                        density='compact'
                        rows={data}
                        columns={columns}
                        sx={{
                            maxHeight:"30rem",
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                              justifyContent: 'center',
                            }
                        }}
                        
                    /> 
            </Box>
    </>
    )
}

export default CodproModal;