import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Box, Divider } from '@mui/material'
import apptheme from '../../theme/default'
import ColorButton from '../../Buttons/ColorButton'
import { capitalize } from '../../Utils/functions'
import { api } from '../../../services/api'
import SearchInput from '../../Utils/SearchInput'
import { externalComponent } from '../../AppRoutes'
import { RowSkeleton } from '../../Utils/skeletons'
import detectScroll from '../../Utils/scrollDetector'
import { mergePaginatedData } from '../../Utils/mergeList'

const Userslist = ({ loadRow, reload }) => {
    const [users, setUsers] = useState([])
    const [fetching, setFetching] = useState(false)
    const { setLoader } = useContext(externalComponent)

    const loadUsers = useCallback(async () => {
        setLoader(true)
        const res = await api.get('/users/?limit=10')
        setLoader(false)
        if (res.status === 200) {
            setUsers(res.data)
        }
    }, [setUsers, setLoader]) 

    useEffect(() => {
        loadUsers()
    }, [reload, loadUsers])

    const loadMoreUsers = async (url) => {
        if (!url || fetching) return
        setFetching(true)
        const res = await api.get(url)
        if (res.status === 200) {
            mergePaginatedData(users, res.data)
        }
        setFetching(false)
    }

    const headerRow = (data) => {
        return (
            <Box
                className='headerRow'
                sx={{ backgroundColor: apptheme.primaryColor, color: apptheme.secondaryColor }}
            >
                <Box className='client-listItem left' sx={{ padding: "1rem" }}>
                    <Box sx={{ flex: 0.2 }}>{data.first_name}</Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ flex: 0.3 }}>{data.phone_number}</Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ flex: 0.5 }}>{data.email}</Box>
                </Box>
            </Box>
        )
    }

    const eachRow = (data) => {
        return (
            <ColorButton
                onClick={() => loadRow(data)}
                sx={{ boxShadow: '1px 1px 5px 1px #cbcbcb' }}
                fColor={apptheme.secondaryColor}
                sColor={apptheme.tertiaryColor}>
                <Box className='client-listItem left readableFont'>
                    {/* <Box sx={{flex:0.1}}>{data.id}</Box>
                    <Divider orientation="vertical" flexItem /> */}
                    <Box sx={{ flex: 0.2 }}>{capitalize(data.first_name)}</Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ flex: 0.3 }}>{data.phone_number}</Box>
                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ flex: 0.5 }}>{data.email}</Box>

                </Box>
            </ColorButton>
        )
    }

    return (
        <>
            <Box className='client-header'>
                <SearchInput path={'/users/'} submit={setUsers} />
                {headerRow({ first_name: 'Nome', phone_number: 'Telefone', email: 'Email' })}
                <Box className='client-list' onScroll={(e) => detectScroll(e, -1, () => loadMoreUsers(users.next))}>
                    {users?.results && users.results.length > 0 ? users.results.map(each => eachRow(each))
                        : <Box className='defaultNotFound'>Nenhum usuário encontrado...</Box>}
                    {users && users.next && <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>}
                </Box>
            </Box>
        </>
    )
}

export default Userslist