import React, { useContext, useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { api } from '../../services/api';
import { externalComponent } from '../AppRoutes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';



const BodyModalOrdes = () => {

    function convertISODate(isodate) {

    if (!isodate) {
        return '';
    }

        const date = new Date(isodate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    const [rows,setRows] = useState({"datacriacao":""})
    const {setLoader } = useContext(externalComponent)

    const getOrders = async () => {

        setLoader(true)
            const res = await api.get(`/log/pedidos/`)
        setLoader(false)
        if (res.status === 200) {
            const data = res.data
            setRows(data)
            return
        }
    }

    const columnsMain = [
    
        {
            field: 'datacriacao',
            headerName: 'Data Criação',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {convertISODate(value)}
              </Box>
            )
        },
        {
            field: 'por',
            headerName: 'Criado por',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'para',
            headerName: 'Enviado para',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'visualizado',
            headerName: 'Visualizado',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {
                value ?
                 <CheckCircleIcon sx={{color:'#00c61f', fontSize:'1.3rem'}} />
                 :
                 <CancelIcon sx={{color:'#c60000', fontSize:'1.3rem'}}/>
               }
              </Box>
            )
        },
        {
            field: 'visualizadoem',
            headerName: 'Visualizado em',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {convertISODate(value)}
              </Box>
            )
        },
        {
          field: 'alteradoem',
          headerName: 'Alterado em',
          flex: 0.4,
          renderCell: ({value}) => (
            <Box sx={{padding:'0px 10px'}}>
             {convertISODate(value)}
            </Box>
          )
        },
        {
            field: 'pedidos',
            headerName: 'Pedidos',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
        }
    ]

    useEffect(() => {
        getOrders()
    },[])


    return(
        <>
        <Box sx={{display:'flex',width:'100%',height:'30rem'}} >

            <DataGrid 
                className='alterned_grid'
                rows={rows}                                                                                                                                                                                                                                                                                                                                                                                                                       
                columns={columnsMain}
                pagination={false}
                density='compact'
                pageSize={5}
                sx={{
                maxHeight:"30rem",
                }}
                rowHeight={27}
            />

        </Box>
        </>
    )

}

export default BodyModalOrdes;