import React, {useContext} from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { api } from '../../services/api';
import { externalComponent } from '../AppRoutes';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';

const IconButton = styled('label')({
    cursor: 'pointer',
    display: 'inline-block',
  });
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

 const types_allowed = ['image/jpeg','image/png','application/pdf']
  
 const InputFileUpload = ({id_order,getActualOrder}) => {

    const {setLoader} = useContext(externalComponent)

    const flushFiles = (e) => {
      e.target.value = '';
    }

    const handleFileChange = (e) => {
      if (e.target.files) {
        const files = e.target.files

        for (let i = 0; i < files.length; i++){

          if(types_allowed.includes(files[i].type)){

            const fileSizeInMB = files[i].size / (1024 * 1024);
            const size_allowed = sessionStorage.getItem("file_size")
            
            if (fileSizeInMB > size_allowed) {
              toast.error(`O arquivo ${files[i].name} é grande demais !`)
            }else{
              uploadFiles(files[i]);
            }
          }
          else{
              toast.error(`Arquivo ${files[i].name} contém extensão não permitida pelo sistema !`)
          }
        }
        
      }
    };
  
    const uploadFiles = async (file) => {

        setLoader(true)
            const formData = new FormData();
            formData.append('file', file);
            formData.append('id_order', id_order);
            const res = await api.post('/uploadorderfile/',formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            })
            if (res.status === 200){
                getActualOrder()
                toast.success('Arquivo anexado com sucesso !')
            }
        setLoader(false)
       

    }


    return (
      <IconButton>
        <Tooltip title='Insere os anexo(s)'>
          <CloudUploadIcon  className='actionIcon'  />
        </Tooltip>
        <VisuallyHiddenInput type="file" multiple="multiple" onClick={flushFiles} onChange={handleFileChange} />
      </IconButton>
    );
  }

export default  InputFileUpload;