import React from 'react'
import Box from '@mui/material/Box';

const BoxTruckRepresentative = ({title,truck_size,colorBack,color}) => {

    return(
    <>
    <Box sx={{display:'flex',flexDirection:'column',width:'9rem'}}>
            <Box sx={{border:'1px solid #ced4da',padding:'0.5rem', fontWeigh:'500', fontSize:'11.5px', textAlign:'center', backgroundColor: colorBack ? colorBack : '#ced4da', color: color ? color : 'black' }}>
                {title}
            </Box>

            <Box>
                <input className='inputSizeNum' type='number' disabled value={truck_size} />
            </Box>
    </Box>
    </>
    )

}

export default BoxTruckRepresentative;