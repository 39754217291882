import React  from 'react';

const Header = () => {
    return (
        <section>
            <div className="text-center">
                <img src='v10.png' alt='v10' style={{width: "66px", height: "66px"}}/>
            </div>
        </section>
    );
};

export default Header;