import React, { useContext, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DistributionContext } from '.';
import ColorButton from '../Buttons/ColorButton';
import { api } from '../../services/api';
import { externalComponent } from '../AppRoutes';
import toast from 'react-hot-toast';
import { distributionInitialState } from '.';
import { sortTables, formatDate } from './functionsCore';

const ModalRepresentative = ({handleClose}) => {

    const {state,setState} = useContext(DistributionContext)
    const [representative,setRepresentative] = useState('')
    const [representatives,setRepresentatives] = useState('')
    const { setLoader } = useContext(externalComponent)

    const changeSelect = (e) => {
        setRepresentative(e.target.value)
    }

    const getDistribution = async () => {
        const body = {
            "dt_inicial": formatDate(state.dtInicial),
            "dt_final": formatDate(state.dtFinal)
        }
      
        setLoader(true)
        const res = await api.post('/distribution/',body)
        if (res.status === 200){
            const sorted_table = sortTables(res.data)
            
            setState({...distributionInitialState,data:sorted_table,dataToResetDist:JSON.stringify(sorted_table),branches:state.branches})
            
        }
        setLoader(false)
    }

    const finalizeDistribution = async () => {

        // if(representative == ''){
        //     toast.error('Selecione um representante !')
        //     return
        // }

        const userId = sessionStorage.getItem("user_id");

        const body = {
            "createBy":userId,
            "representative":representative,
            "tables": state.consolidatedStep4,
            "branch": state.selectedFilial,
            "message": state.message 
        }
        setLoader(true)
            const res = await api.post('/whatsapp/',body)
        setLoader(false)

        if (res.status === 200){
           handleClose()
           getDistribution()
           toast.success('Carga finalizada com sucesso !')
        }
    }

    const getRepresentatives = async () => {

        setLoader(true)
            const res = await api.get(`/representantes/${state.selectedFilial}`)
        setLoader(false)
        if (res.status === 200) {
            const data = res.data
            setRepresentatives(data)
            return
        }
    }

    useEffect(() => {
        getRepresentatives()
    },[])

    return(
        <>
        <Box sx={{ minWidth: 40 }}>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Representantes</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Representantes"
                    value={representative}
                    onChange={changeSelect}
                    >
                    {/* <MenuItem value={1}>Teste</MenuItem> */}
                    {
                        Object.entries(representatives).map(([key, value]) => (
                            <MenuItem key={key} value={value.OID}>{value.Usuario}</MenuItem>
                        ))
                    }

                    </Select>
                </FormControl>

           
            

            </Box>
            <br/>
            <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                <ColorButton onClick={finalizeDistribution} > Finalizar </ColorButton>
            </Box>
        </>
    )
}

export default ModalRepresentative;