import Box from '@mui/material/Box';
import React from 'react'

const ConfirmModal = ({type}) => {
    return(
        <>
            <Box sx={{display:'flex',flexDirection:'column'}}>


                <Box sx={{fontSize:'15px'}}>
                  Tem certeza que deseja {type} um caminhão ? 
                </Box>

            </Box>
        </>
    )
}

export default ConfirmModal