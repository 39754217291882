import React from 'react'

import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const Toolbar = (status) => {
    return(
    <>
     <GridToolbarContainer sx={{display:'flex',justifyContent:'space-between'}}>
        <Box sx={{flex:1}}>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
        </Box>

        <Box>
            <CircleIcon sx={{color: status ? 'green' : 'gray'}}/>
        </Box>
     </GridToolbarContainer>
    </>
    )
}

export default Toolbar;