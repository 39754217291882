import React, {useState} from 'react'

import { TextField, InputAdornment, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { api } from '../../services/api';
import apptheme from '../theme/default';
import LoadingIcon from './LoadingIcon';

var timeout = 0

const SearchInput = ({submit, path}) => {
    const [text, setText] = useState('')
    const [smallLoading, setSmallLoading] = useState(false)

    const requestData = async (value) => {

        if (!path) return

        setSmallLoading(true)
        const res = await api.get(`${path}?search=${value}&limit=10`)
        setSmallLoading(false)        

        if (res.status === 200){
            if ('results' in res.data)
                submit(res.data)
        }

    }

    const changeValue = (e) => {
        const newvalue = e.target.value
        setText(newvalue)
        clearTimeout(timeout)
        timeout = setTimeout(() => {requestData(newvalue); timeout=0}, 700)

    }



    return (
        <Box className='searchInputDefault'>
        <TextField
        
        id="input-with-icon-textfield"
        label="Procurar"
        value={text}
        onChange={changeValue}
        sx={{backgroundColor:apptheme.secondaryColor, maxWidth: "15rem"}}
        InputProps={{
        endAdornment: 
            <LoadingIcon loading={smallLoading}/>
            ,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }
    }
        variant="outlined"
      />
      </Box>
    )
}

export default SearchInput
