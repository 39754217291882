import React, {useState} from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const MessageStep4 = ({setState}) => {

    const [message,setMessage] = useState("")

    const changeMessage = (e) => {
        const value = e.target.value
        setMessage(value)
      }

    const changeStateMessage = () => {
      setState({message:message})
    }

    return(
        <>
        <Box sx={{display:'flex',flexDirection:'column'}}>
              Mensagem para o representante:
               <TextField
                
                id="outlined-multiline-static"
                multiline
                rows={4}
                value={message}
                onChange={changeMessage}
                onMouseLeave={changeStateMessage}
                inputProps={{
                    maxLength:5000,
                    style: {
                      outlineColor: 'black',
                      textWrap:'wrap'
                    },
                  }}
                />
        </Box>

        </>
    )
}

export default MessageStep4;