import React, { useContext,useState, useEffect } from 'react'
import { DistributionContext } from '.';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import BoxInfo from './boxInfo';
import CargoBoxModalStep2 from './cargoBoxModalStep2';
import './style.css'
import { sortTables } from './functionsCore';
import { stringToInt } from './functionsCore';

const ModalStep2 = () => {

    const {state,setState} = useContext(DistributionContext)
    const [allTable,setAlltable] = useState([])
    const [datagridModalStep2,setDatagridModalStep2] = useState({})

    const columns = [
    
        {
            field: 'aro',
            headerName: 'Aro',
            flex: 0.6,
            cellClassName: () => {
                return clsx('default-app bold', {
                    selected: true ,
                });
            }
        },
        {
            field: 'medida',
            headerName: 'Medida',
            flex: 0.8,
            cellClassName: () => {
                return clsx('default-app bold align-start', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estba',
            headerName: 'Est BA',
            flex: 0.5,
            cellClassName: (params) => {
            if (params.field === state.selectedFilial) {
                return clsx('super-app est-tables est-tables', {
                    selected: true ,
                });
            }else{
                return clsx('default-app est-tables est-tables', {
                    selected: true ,
                });
            }
          }
        },
        {
            field: 'estce',
            headerName: 'Est CE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estmt',
            headerName: 'Est MT',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estpe',
            headerName: 'Est PE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estse',
            headerName: 'Est SE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estpb',
            headerName: 'Est PB',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estrn',
            headerName: 'Est RN',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estpa',
            headerName: 'Est PA',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venba',
            headerName: 'Ven BA',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'vence',
            headerName: 'Ven CE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venmt',
            headerName: 'Ven MT',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venpe',
            headerName: 'Ven PE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'vense',
            headerName: 'Ven SE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venpb',
            headerName: 'Ven PB',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venrn',
            headerName: 'Ven RN',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venpa',
            headerName: 'Ven PA',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'dispbks',
            headerName: 'BKS Disp',
            flex: 0.5,
            cellClassName: () => {
                return clsx('default-app dep-bks', {
                    selected: true ,
                });
            }
        },
        {
            field: state.reservBranch,
            headerName: state.reservBranchName,
            flex: 0.7,
            cellClassName: () => {
                return clsx('default-app reserv', {
                    selected: true ,
                });
            }
        },
        {
            field: 'cargo',
            headerName: 'Carga',
            flex: 0.7,
            renderCell: (params) => (
              <Box >
                <CargoBoxModalStep2 aro={params.row.aro} med={params.row.medida} />
              </Box>
            )
          }

    ]

    const setRows = () => {
        const lines = [...state.distrA,...state.distrB]
        const sorted_lines = sortTables(lines)
        setAlltable(sorted_lines)
    }

    const handleColumnVisibilityChange = (params) => {
        setDatagridModalStep2(params)
        const stringColumn = JSON.stringify(params)
        localStorage.setItem('datagrid-modalstep2', stringColumn );
       };

    useEffect(() => {
        setRows()

        const lenTables = state.consolidatedStep4.length
        let totalAB = 0
        for (let i = 1; i <= lenTables; i++) {
            
            let lines = state.consolidatedStep4.filter(each => each[`table${i}`])[0][`table${i}`]['lines']
            lines.map(each => totalAB+= stringToInt(each.cargo))

        }

        setState({totalAB:totalAB})

        const storedColumns = localStorage.getItem('datagrid-modalstep2');
        if (storedColumns) {
            setDatagridModalStep2(JSON.parse(storedColumns));
        }

        
    },[])

    return (
        <>
        <Box sx={{display:'flex',flexDirection:'row',flexWrap:'wrap',height:'40rem'}}>

            <Box sx={{display:'flex',flexDirection:'row', gap:'2rem'}}>
                <BoxInfo title={'TOTAL DE PNEUS'} value={state.totalAB} colorBack={'#c60000'} color={'white'}/>
            </Box>

            <Box sx={{width:'100%'}} >
            <br/>
                <DataGrid
                        rowHeight={state.rowHeight}
                        className='alterned_grid'
                        density='compact'
                        rows={allTable}
                        columns={columns}
                        columnVisibilityModel={datagridModalStep2}
                        onColumnVisibilityModelChange={(newModel) =>
                            handleColumnVisibilityChange(newModel)
                        }
                        sx={{
                            '& .super-app.selected': {
                                fontWeight: '600',
                                justifyContent:'center',
                                alignItems:'center',
                            },
                            '& .default-app.selected': {
                                justifyContent:'center',
                                alignItems:'center',
                            },
                            maxHeight:"30rem",
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                justifyContent: 'center',
                              },
                            
                        }}
                        
                    /> 
            </Box>


        </Box>
        </>
    )
}

export default ModalStep2