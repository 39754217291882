import React, { useContext } from 'react'
import { DistributionContext } from '.';
import { externalComponent } from '../AppRoutes';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import SendBox from './sendBox';
import ColorButton from '../Buttons/ColorButton';
import './datagrid.css'
import clsx from 'clsx';
const Step3 = () => {

    const {state,setState} = useContext(DistributionContext)
    const {setLoader} = useContext(externalComponent)

    const columnsMain = [
    
      {
          field: 'aro',
          headerName: 'Aro',
          flex: 0.5,

          renderCell: ({value}) => (
            <Box sx={{padding:'0px 10px',fontWeight: 'bold'}}>
             {value}
            </Box>
          )
      },
      {
        field: 'medida',
        headerName: 'Medida',
        flex: 0.5,
        cellClassName: () => {
          return clsx('medida-row', {
              selected: true ,
          });
        }
      },
      {
        field: 'cargo',
        headerName: 'À enviar',
        flex: 0.5,
        renderCell: ({value}) => (
          <Box sx={{padding:'0px 10px',fontWeight: 'bold'}}>
           {value}
          </Box>
        )
      },
      {
        field: 'codpro',
        headerName: 'Codpro',
        flex: 0.4,
        renderCell: (params) => (
          <Box sx={{display:'flex',flexDirection:'column', width:'100%'}}>
            {
              params.row.list.map((each) => (
              
                <Box className={each.id % 2 === 0 ? 'even' : 'odd'} sx={{ textAlign:"center", fontWeight:"bolder" }}>
                  {each.codpro}
                </Box>
              
              ))
            }

          </Box>
        ),
      },
      {
        field: 'descr',
        headerName: 'Descrição',
        flex: 2.3,
        renderCell: (params) => (
          <Box sx={{display:'flex',flexDirection:'column', width:'100%',justifyContent:'start'}}>
            
            {
              params.row.list.map((each) => (

                <Box className={each.id % 2 === 0 ? 'even' : 'odd'} sx={{  textAlign:"start", fontWeight:"bolder" }}>
                  {each.descr}
                </Box>
              ))
            }

          </Box>
        ),
      },
      {
        field: state.selectedFilial,
        headerName: `Est ${state.branch.toUpperCase()}`,
        flex: 0.5,
        renderCell: (params) => (
          
          <Box sx={{display:'flex',flexDirection:'column', width:'100%'}}>
            
            {

              params.row.list.map((each) => (

                each[state.selectedFilial] ? 
                <Box className={each.id % 2 === 0 ? 'even est-tables' : 'odd est-tables'} sx={{ textAlign:"center", fontWeight:"bolder" }}>
                  {each[state.selectedFilial]}
                </Box>
                :
                <Box className={each.id % 2 === 0 ? 'even' : 'odd'} sx={{ textAlign:"center", fontWeight:"bolder" }}>
                  - 
                </Box>
              ))
            }

          </Box>
        ),
      },
      {
        field: state.selectedFilialVen,
        headerName: `Ven ${state.branch.toUpperCase()}`,
        flex: 0.5,
        renderCell: (params) => (
          <Box sx={{display:'flex',flexDirection:'column', width:'100%'}}>
            
            {
              params.row.list.map((each) => (
               
                each[state.selectedFilialVen] ? 
                <Box className={each.id % 2 === 0 ? 'even ven-tables' : 'odd ven-tables'} sx={{ textAlign:"center", fontWeight:"bolder"}}>
                  {each[state.selectedFilialVen]}
                </Box>
                :
                <Box className={each.id % 2 === 0 ? 'even' : 'odd'} sx={{ textAlign:"center", fontWeight:"bolder" }}>
                  -
                </Box>
               
              ))
            }

          </Box>
        ),
      },
      {
        field: 'dispbks',
        headerName: 'BKS Disp',
        flex: 0.5,
        renderCell: (params) => (
          <Box sx={{display:'flex',flexDirection:'column', width:'100%'}}>
            
            {
              params.row.list.map((each) => (
                each.dispbks ? 
                <Box className={each.id % 2 === 0 ? 'even dep-bks' : 'odd dep-bks'} sx={{ textAlign:"center", fontWeight:"bolder" }}>
                  {each.dispbks}
                </Box>
                :
                <Box className={each.id % 2 === 0 ? 'even' : 'odd'} sx={{ textAlign:"center", fontWeight:"bolder" }}>
                  - 
                </Box>
              ))
            }

          </Box>
        ),
      },
      {
        field: 'envio',
        headerName: 'Envio',
        flex: 0.5,
        renderCell: (params) => (
          <Box sx={{display:'flex',flexDirection:'column', width:'100%',justifyContent:'start'}}>
            {
              params.row.list.map((each) => (

                <Box className={each.id % 2 === 0 ? 'even' : 'odd'} >
                  <SendBox codpro={each.codpro} aro={each.aro} med={each.medida} envio={each.envio}
                   cargo={params.row.cargo} listFull={each} row={params.row}
                  />
                </Box>
              
              ))
            }

          </Box>
        ),
        
      },
      {
        field: 'diferenca',
        headerName: 'Diferenca',
        flex: 0.3,

        renderCell: ({value}) => (
          <Box sx={{padding:'0px 10px',fontWeight: 'bold'}}>
           {value}
          </Box>
        )
    },


    ]

    const columnsPass = [

      {
          field: 'aro',
          headerName: 'Aro',
          flex: 0.7,
          renderCell: ({value}) => (
            <Box sx={{padding:'0px 10px',fontWeight: 'bold'}}>
             {value}
            </Box>
          )
      },
      {
        field: 'medida',
        headerName: 'Medida',
        flex: 0.6,
        cellClassName: () => {
          return clsx('medida-row', {
              selected: true ,
          });
        }
      },
      {
        field: 'codpro',
        headerName: 'Codpro',
        flex: 0.5,
        renderCell: ({value}) => (
          <Box sx={{padding:'0px 10px'}}>
           {value}
          </Box>
        )
      },
      {
        field: 'descr',
        headerName: 'Descrição',
        flex: 2,
        cellClassName: () => {
          return clsx('descr-row', {
              selected: true ,
          });
        }
      },
      {
        field: 'peso',
        headerName: 'Peso',
        flex: 1,
        renderCell: ({value}) => (
          <Box sx={{padding:'0px 10px'}}>
           {value}
          </Box>
        )
      },
      {
        field: 'm3',
        headerName: 'm³',
        flex: 1,
        renderCell: ({value}) => (
          <Box sx={{padding:'0px 10px'}}>
           {value}
          </Box>
        )
      },
      {
        field: 'cargo',
        headerName: 'Envio',
        flex: 1,
        renderCell: ({value}) => (
          <Box sx={{padding:'0px 10px'}}>
           {value}
          </Box>
        )
      }
      

    ]

    function handleButtonClick() {
    
      state.functionStep3(state,setState,setLoader,'update')
  
    }

 
    return (
      <>
      <Box sx={{display:'flex',flexDirection:'row', justifyContent: 'flex-end',paddingBottom: '1rem' }} >
          <ColorButton  className='realpadding' onClick={handleButtonClick}>Atualizar</ColorButton>
      </Box>

      <Box sx={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >

        <Box sx={{width:'100%',height:'20rem'}} className='step3_datagrid'>
          <DataGrid 
                    rows={state.consolidatedListA}                                                                                                                                                                                                                                                                                                                                                                                                                       
                    columns={columnsMain}
                    pagination={false}
                    density='compact'
                    pageSize={5}
                    getEstimatedRowHeight={() => 100}
                    getRowHeight={() => 'auto'}
                    sx={{
                      maxHeight:"30rem",
                      '& .MuiDataGrid-columnHeaderTitleContainer': {
                        justifyContent: 'center',
                      },
                      '& .MuiDataGrid-cell--withRenderer':{
                        justifyContent: 'center',
                      },
                      '& .MuiDataGrid-row':{
                        borderBottom:'2px solid #6c6d6e'
                      },
                    }}
          />
        </Box>

        <Box sx={{width:'100%',height:'30rem'}}>
          <br/><br/><br/>
          <DataGrid
              rowHeight={state.rowHeight}
              className='alterned_grid'
              sx={{
                maxHeight:"20rem",
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  justifyContent: 'center',
                },
                '& .MuiDataGrid-cell--withRenderer':{
                  justifyContent: 'center',
                }
              }}
              rows={state.consolidatedListB}                                                                                                                                                                                                                                                                                                                                                                                                                       
              columns={columnsPass}
              pagination={false}
              density='compact'
            />
        </Box>

      </Box>
    </>
    )
}

export default Step3;