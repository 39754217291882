import React from 'react'
import Box from '@mui/material/Box'

const ModalText = ({text}) => {
    return(
        <>
        <Box sx={{display:'flex',flexDirection:'column',width:'100%'}}>

            <Box sx={{fontSize:'15px', textWrap: 'wrap' }}>
                {text} 
            </Box>
        </Box>
        </>
    )
}

export default ModalText;