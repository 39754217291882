import React, {useState, useContext,useEffect} from 'react';
import { externalComponent } from '../AppRoutes';
import { PainelContext } from './painel';
import { Box } from '@mui/material';
import { api } from '../../services/api';
import { defaultBackend } from '../../services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import DefaultModal from '../Utils/DefaultModal';
import ModalText from '../Distribuicao/modalText';
import ColorButton from '../Buttons/ColorButton';
import Tooltip from '@mui/material/Tooltip';


const AttachmentModal = ({getActualOrder}) => {

    const {setLoader} = useContext(externalComponent)
    const {state} = useContext(PainelContext)

    const [orderFiles,setOrderFiles] = useState([])
    const [modalDelete,setModalDelete] = useState(false)
    const [idAttachment,setIdAttachment] = useState(1)
    const [filename,setFilename] = useState('')

    const types_images_allowed = ['jpg','jpeg','png']

    const closeModalDelete = () => {
        setModalDelete(false)
    }

    const verifyType = (namefile) => {
        const type_file = namefile.split('.').pop()
        if(types_images_allowed.includes(type_file)){
            return true
        }else{
            return false
        }
        
    }

    const getOrderFiles  = async () => {

        setLoader(true) 
        const res = await api.get(`/orderfiles/${state.id_order}`)
        if (res.status === 200){
            const data = res.data
            getActualOrder()
            setOrderFiles(data)
        }
        setLoader(false)

    }

    const deleteAttachment = async () => {
        const body = {
            'id_attachment': idAttachment,
            'filename': filename
        }

        setLoader(true) 
        const res = await api.post(`/deleteorderfile/`,body)
        if (res.status === 200){
            const data = res.data
            getOrderFiles()
        }

       
        setModalDelete(false)
        setLoader(false)
    }

    useEffect(() => {
        getOrderFiles()
    },[])

    const openModalDelete = (id_attachment,filename) => {
        setIdAttachment(id_attachment)
        setFilename(filename)
        setModalDelete(true)
    }

    const buttonConfirmRelease = () => {
        return(
        <Box>
            <ColorButton onClick={deleteAttachment}>Sim</ColorButton>
        </Box>
        )
    }

    return(
        <>

        <DefaultModal dialogProps={{maxWidth:'xs'}} open={modalDelete} handleClose={closeModalDelete} content={<ModalText text={'Tem certeza que deseja deletar este anexo ?'}/>} action={buttonConfirmRelease()}
            title={'Deletar anexo'}/>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5rem' }}>
        {
            
            orderFiles.length !== 0 ?
            
                orderFiles.map((each) => (
                    <Box className='box_order_img' key={each.filename}>
                    {verifyType(each.filename)? (
                        <>
                            <img className='order_img' src={`${defaultBackend}static/${each.filename}`} alt={each.filename} />
                            
                        </>
                    ) : 
                    <>
                        <iframe className='order_pdf' src={`${defaultBackend}static/${each.filename}`} />
            
                    </>
                    
                    }
                    <Tooltip title='Deleta o anexo'>
                        <DeleteIcon className='actionIcon' onClick={()=> openModalDelete(each.id,each.filename)} />
                    </Tooltip>
                    </Box>
                ))
            :

            <Box>
                <h3>Não tem arquivos anexados.</h3>
            </Box>
        }
        </Box>
        </>
    )
}

export default AttachmentModal;