import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const PasswordBtn = (props) => {

    const changePassowrdHandler = (e) => {
        props.changePass(e)
    }

    const [showPassword, setShowPassword] = React.useState(false);
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
        <FormControl fullWidth={true} variant="outlined">
            <InputLabel id="pass-label" htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                labelId='pass-label'
                onChange={changePassowrdHandler}
                value={props.passProp}
                id="txtUserPassword"
                type={showPassword ? 'text' : 'password'}
                required
                fullWidth
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                label="Senh"
                />
        </FormControl>
    );
}

export default PasswordBtn;