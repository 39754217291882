import { api } from '../../../services/api';

const Logout = () => {


    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('grupo');
    sessionStorage.removeItem('hash');
    sessionStorage.removeItem('oid_enterprise')
    sessionStorage.removeItem('file_size')
    api.defaults.headers.Authorization = null;
    window.location.reload()

}


export default Logout