import   React            , { useState, useCallback, useContext, useReducer }    from 'react'
import { Dialog           , DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import { defaultReducer    }                                                     from '../../Utils/reducers'
import { IconButton       , Tooltip, Box, TextField }                            from '@mui/material'
import   CancelIcon                                                              from '@mui/icons-material/Cancel';
import   apptheme                                                                from '../../theme/default'       ;
import   ColorButton                                                             from '../../Buttons/ColorButton' ;
import { toast             }                                                     from 'react-hot-toast'           ;
import { api               }                                                     from '../../../services/api'     ;
import { externalComponent }                                                     from '../../AppRoutes'           ;


const EmailTestDialog = ({ object, handleClose }) => {

    const { setLoader } = useContext(externalComponent)

    const [error, setError] = useState('')

    const [state, setState] = useReducer(defaultReducer, {
        ...object,
        recipients: '',
        subject: '',
        message: ''
    })


    const submitData = async () => {
        setLoader(true)
        const res = await api.post(`/email/testemail/`, state)
        setLoader(false)
        if (res.status === 200) {
            toast.success('Email enviado com sucesso!')
            handleClose()
            return
        }
        setError(res.data)
        toast.error('Ocorreu um erro enviar o email.')

    }

    const detectValidData = useCallback(() => {
        const resp = []
        if (state.recipients.length === 0)
            resp.push(<Box>Destinatários não pode estar vazio.</Box>)
        if (state.subject.length === 0)
            resp.push(<Box>Assunto não pode estar vazio.</Box>)
        if (state.message.length === 0)
            resp.push(<Box>Mensagem não pode estar vazia.</Box>)
        if (resp.length === 0)
            return ''
        return resp
    }, [state.recipients, state.message, state.subject])

    const validData = detectValidData()

    return (
        <>
            <Dialog
                open={true}
                fullWidth
                className='item-dialog'
            >
                <DialogTitle><span>Enviar mensagem de teste</span>
                    <IconButton onClick={handleClose} ><CancelIcon
                        sx={{ color: apptheme.tertiaryColor }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box className='email-test-rows'>
                        <Tooltip placement="top" title={'Destinatários separados por virgula.'}>
                            <TextField
                                id="desiny"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Destinatários"
                                value={state.recipients}
                                onChange={(e) => { setState({ 'recipients': e.target.value }) }}
                                fullWidth={true}
                            />
                        </Tooltip>

                        <TextField
                            id="subject"
                            type="text"
                            sx={{ backgroundColor: 'white' }}
                            label="Assunto"
                            value={state.subject}
                            onChange={(e) => { setState({ 'subject': e.target.value }) }}
                            fullWidth={true}
                        />

                        <TextField
                            multiline
                            minRows={4}
                            maxRows={10}
                            id="message"
                            type="text"
                            className='breaking-text-area'
                            sx={{ backgroundColor: 'white' }}
                            label="Mensagem"
                            value={state.message}
                            onChange={(e) => { setState({ 'message': e.target.value }) }}
                            fullWidth={true}
                        />

                        {error.length > 0 &&
                            <Box className='email-test-error' sx={{color:'red'}}>
                                Erro: {error}
                            </Box>}

                    </Box>

                </DialogContent>
                <DialogActions sx={{padding:'1rem', paddingRight:'1.5rem'}}>
                    <Tooltip placement="left" title={validData}>
                        <Box>
                            <ColorButton
                                onClick={submitData}
                                disabled={validData}
                                fColor={apptheme.tertiaryColor}
                                sColor={apptheme.quaternaryColor}
                            >Enviar
                            </ColorButton>
                        </Box>
                    </Tooltip>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default EmailTestDialog