import React  from 'react';
import Container from '@mui/material/Container';
import InputsLogin from './inputsLogin';
import Footer from './footer';
import Header from './header';
import './login.css'
import { Box } from '@mui/material';


const LoginComponent = (props) => {

    const changeStatusValue = (status) => {
        props.onLogin(status)
    }

    return (
        <Box sx={{padding:'2rem'}} className='root-container'>
            <Container component="main" maxWidth="xs" className="mainContainer">
                <Header />
                <InputsLogin onGetLogin={changeStatusValue} />
                <Footer />
            </Container>
        </Box>
    );
};

export default LoginComponent;