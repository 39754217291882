import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ColorButton from '../Buttons/ColorButton';
import toast from 'react-hot-toast';
import { RepresentativeContext } from './distributionR';
import { altercam, addcam, deletecam, stringToInt } from './functionsCore';


const ModalTransferRepresentative = ({table,row,handleClose}) => {

    const {state,setState} = useContext(RepresentativeContext)

    const [options,setOptions] = useState("")
    const [selectedTable,setSelected] = useState("")
    const [transferValue,setTransfer] = useState(stringToInt(row.cargo))

    const changeSelect = (e) => {
        const value = e.target.value
        setSelected(value)
        
    }

    const changeTransferValue = (e) => {
        const value = e.target.value

        if(value <= row.cargo){
            setTransfer(value)
        }

        if(value < 0){
            setTransfer(1)
        }

    }

    const transferRow = () => {

        let checkValue = false
        let checkTable = false

        if(transferValue === 0){
            toast.error('Informe o valor a ser transferido !')
            return
        }else{
            checkValue = true
        }

        if(selectedTable === ""){
            toast.error('Selecione o caminhão de transferência !')
            return
        }else{
            checkTable = true
        }

        if(checkTable === true && checkValue === true){
            const consolidated = state.data
            const tableName = `table${selectedTable}`

            const equation = row.cargo - transferValue
        
            if(equation === 0){

                for ( const each of consolidated){

                    if(each[table]){

                        let lines = each[table]['lines']
                
                        let newLines = lines.filter(each => each.id !== row.id)
                        each[table]['lines'] = newLines
                        
                        deletecam(state.sugestions,row.codpro,table, row)
                    }

                    if(each[tableName]){
                        let lines = each[tableName]['lines']
                        let newLines = lines.filter(each => each.id !== row.id)
                        let current_line = lines.filter(each => each.id === row.id)
                        let current_value = 0

                        if(current_line.length !== 0){
    
                            current_value = current_line[0].cargo
                        }

                        let current_row = row
                        const cargoValue = stringToInt(transferValue) + stringToInt(current_value)
                        current_row['cargo'] = cargoValue
                        each[tableName]['lines'] = [...newLines,current_row]

                        addcam(state.sugestions,row.codpro,cargoValue,tableName,row)
                    }
                    
                }
      
            } else if(equation !== 0){
            
                for ( const each of consolidated){

                    if(each[table]){

                        let lines = each[table]['lines']
                        let newLines = lines.filter(each => each.id !== row.id)
                        const current_row = row
                        current_row.cargo = equation
                        each[table]['lines'] = [...newLines,current_row]

                        altercam(state.sugestions,row.codpro,equation,table,row)
                      
                       
                    }

                    if(each[tableName]){

                        let lines = each[tableName]['lines']
                        let newLines = lines.filter(each => each.id !== row.id)
                        let current_line = lines.filter(each => each.id === row.id)
                        let current_value = 0

                        if(current_line.length !== 0){
                            current_value = current_line[0].cargo
                        }

                        const newValue = parseInt(transferValue) + parseInt(current_value)
                        const current_row_other = row
                        const newDict = {...current_row_other,"cargo":newValue}
                        each[tableName]['lines'] = [...newLines,newDict]

                        addcam(state.sugestions,row.codpro,newValue,tableName,row)
                        altercam(state.sugestions,row.codpro,newValue,tableName,row)

                    }
                    
                }

            }
            setState({data:consolidated})
            
            setState({changedTables:!state.changedTables})
  
        }

        handleClose()
    }

    const selectOptions = () => {
        
        let takeList = [ ]
        state.data.forEach((each) => {
        let obj = Object.keys(each);
            takeList.push(obj[0])
        });

        let newList = takeList.filter(each => each !== table)
        let newDict = []
        for (const each of newList){
            newDict.push({"id":each.replace('table','')})
        }

        setOptions(newDict)
        selectAutoTruck(state.data)
    }

    const getNextPosition = (position, table) => {
        if (table[position])
            return table[position] + 1
        return 1
    }

    const selectAutoTruck = (ids) => {
        const currentNum = table.replace('table','')
        const trucks = Array(ids.length).fill(0).map((each, index) => index)
        const maxId = getNextPosition(currentNum,trucks)

        setSelected(maxId)
    }

    useEffect(() => {
        selectOptions()
    },[])

    return(
        <>
        <Box sx={{ minWidth: 40,display:'flex',flexDirection:'column',gap:'2rem' }}>
                <FormControl fullWidth sx={{gap:'3rem'}} size="small">

                    <Box>
                        <InputLabel id="demo-simple-select-label">Caminhões</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Caminhões"
                        value={selectedTable}
                        onChange={changeSelect}
                        sx={{width:'100%'}}
                        >
                        
                        {
                        
                            Object.entries(options).map(([key,value]) => (
                                <MenuItem key={value.id} value={value.id}>Caminhão {value.id}</MenuItem>
                            ))
                        }

                        </Select>
                    </Box>  

                    <Box>
                        <TextField
                        fullWidth
                            label="Pneus"
                            size="small"
                            type="number"
                            value={transferValue}
                            onChange={changeTransferValue}
                            inputProps={{
                                step: 1, // The increment/decrement amount when using the up/down buttons
                                min: 0, // The minimum value
                                max: row.cargo, // The maximum value
                            }}
                            />
                    </Box>
                        
                </FormControl>
                
               
                <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                    <ColorButton onClick={transferRow} >Transferir</ColorButton>
                </Box>
                

            </Box>
         
        </>
    )
}
export default ModalTransferRepresentative;