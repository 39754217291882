import React, { useState } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import DefButton from '../../Buttons/defbutton'
import apptheme from '../../theme/default'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import Userslist from './Userslist';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmationDialog from '../../Information/ConfirmationDialog';
import EditUsers from './EditUsers';
import { api } from '../../../services/api';
import validateData from './validateData';
import toast from 'react-hot-toast'

const url = '/users/'

const Users = () => {

    const [data, setData] = useState({})
    const [showItemModal, setShowItemModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [reload, setReload] = useState(false)

    const reloadState = () => { setReload(p => !p) }

    const loadRow = (row) => {
        setShowItemModal(true)
        setData(row)
    }

    const submitDelete = async () => {
        const res = await api.delete(`${url}${data.id}/`)

        if (res.status === 204) {
            toast.success('Deletado com sucesso')
            setShowItemModal(false)
            setShowDeleteModal(false)
            reloadState()
            return
        }
        else if (res.status === 400){
            toast.error('Não é possível deletar o seu próprio usuário')
        }
        console.error('Falha ao deletar')

    }

    const getTitle = () => {
        if (data.id)
            return 'Editar'
        return 'Adicionar'
    }

    const submit = async () => {

        let res = null
        if (data.id)
            res = await api.patch(`${url}${data.id}/`, data)
        else
            res = await api.post(url, data)



        if (res.status === 201 || res.status === 200) {
            toast.success(`Usuário ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
            setShowItemModal(false)
            reloadState()
            return
        }
        console.error('Requisição falhou')

    }

    const passwordReset = async () => {
        const res = await api.post(`${url}reset/`, data)
        if (res.status === 200) {
            toast.success('Senha resetado com sucesso! O usuário receberá a nova senha por e-mail')
            setShowPasswordReset(false)
            return
        }
        toast.error('Ocorreu um erro ao resetar a senha do usuário.')
    }

    const validData = validateData(data)

    return (
        <>
            <ConfirmationDialog
                open={showDeleteModal}
                title={'Deseja deletar o usuário selecionado?'}
                submit={submitDelete}
                handleClose={() => setShowDeleteModal(false)}
            />
            <ConfirmationDialog
                open={showPasswordReset}
                title={'Deseja resetar a senha do usuário??'}
                submit={passwordReset}
                handleClose={() => setShowPasswordReset(false)}
            />

            {showItemModal &&
                <>
                    <DefaultModal
                        title={
                            <>
                                <span>{getTitle()} Usuário</span>
                                <IconButton onClick={() => setShowItemModal(false)} ><CancelIcon
                                    sx={{ color: apptheme.tertiaryColor }} />
                                </IconButton>
                            </>
                        }
                        content={
                            <EditUsers
                                data={data}
                                setData={setData}
                            />}
                        action={
                            <>

                                {data?.id &&
                                    <>
                                        <Box>
                                            <ColorButton
                                                onClick={() => setShowDeleteModal(true)}
                                                fColor={apptheme.tertiaryColor}
                                                sColor={apptheme.quaternaryColor}>
                                                Excluir
                                            </ColorButton>
                                        </Box>
                                        <Box>
                                            <ColorButton
                                                onClick={() => setShowPasswordReset(true)}
                                                fColor={apptheme.tertiaryColor}
                                                sColor={apptheme.quaternaryColor}>
                                                Resetar senha
                                            </ColorButton>
                                        </Box>
                                    </>
                                }

                                <Tooltip placement="left" title={validData}>
                                    <Box>
                                        <ColorButton
                                            onClick={submit}
                                            disabled={validData}
                                            fColor={apptheme.tertiaryColor}
                                            sColor={apptheme.quaternaryColor}
                                        >{getTitle()}
                                        </ColorButton>
                                    </Box>
                                </Tooltip>
                            </>
                        }
                    />
                </>
            }
            <Box className='information-table'>
                <Box className='header'>
                    <Box className='left'>
                        <DefButton onClick={() => { setShowItemModal(true); setData({}) }}
                            backgroundcolor={apptheme.quaternaryColor}>
                            <AddCircleOutlineIcon />
                        </DefButton>
                    </Box>
                </Box>
                <Box className='data'>
                    <Userslist loadRow={loadRow} reload={reload} />
                </Box>

            </Box>
        </>
    )
}

export default Users