import React from 'react'
import { Skeleton, Button } from '@mui/material'


export const RowSkeleton = ({visibleCallback}) => {

    return (
        <Button className='notes-listItem readableFont left' sx={{padding:0}}>
            <Skeleton on sx={{ width: "100%", height: '3rem', transform: 'none', padding: '0.5rem' }} />
        </Button>
    )
}

