import React, { useState } from 'react'
import apptheme from '../../theme/default'
import ColorButton from '../../Buttons/ColorButton'
import DefaultModal from '../../Utils/DefaultModal'
import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';

var regularExpression = new RegExp("^^(?=.*[A-Z]{"+1+",})" +
    "(?=.*[a-z]{"+1+",})(?=.*[0-9]{"+1+",})" +
    "(?=.*[!@#$-_?.:{]{"+1+",})" +
    "[a-zA-Z0-9!@#$-_?.:{]{"+8+","+50+"}$");

const ChangePassword = ({ ChangePassword, handleClose }) => {

    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')

    const validateData = () => {
        const return_data = []
        if (newPass.length === 0 || confirmPass.length === 0)
            return_data.push(<Box>Campos não podem estar vazios</Box>)
        if (!newPass.match(regularExpression))
            return_data.push(<>
            <Box>Senha precisa de 8 a 50 digitos</Box>
            <Box>1 letra minúscula, 1 letra maiúscula</Box>
            <Box>Caracter especial</Box></>)
        if (newPass !== confirmPass)
            return_data.push(<Box>Campos não são identicos</Box>)
        

        if (return_data.length === 0)
            return ''
        return (return_data)
    }

    return (
        <DefaultModal
            title={
                <>
                    <span>Alterar senha</span>
                    <IconButton onClick={handleClose} ><CancelIcon
                        sx={{ color: apptheme.tertiaryColor }} />
                    </IconButton>
                </>
            }
            content={
                <>
                    <Box sx={{ marginTop: '0.5rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <TextField
                            label='Nova senha'
                            value={newPass}
                            type='password'
                            fullWidth
                            onChange={(e) => {setNewPass(e.target.value)}}
                        />
                        <TextField
                            label='Confirme a senha'
                            value={confirmPass}
                            type='password'
                            fullWidth
                            onChange={(e) => setConfirmPass(e.target.value)}
                        />
                    </Box>
                </>
            }
            action={
                <>

                    <Tooltip placement="left" title={validateData()}>
                        <Box>
                            <ColorButton
                                onClick={() => ChangePassword(newPass)}
                                disabled={validateData()}
                                fColor={apptheme.tertiaryColor}
                                sColor={apptheme.quaternaryColor}
                            >Confirmar
                            </ColorButton>
                        </Box>
                    </Tooltip>

                </>
            }
        />
    )
}

export default ChangePassword