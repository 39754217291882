import { Box } from "@mui/material"

const validateData = (data) => {
  
    const response = []

    // if (!data?.start?.length > 0)
    //     response.push(<Box>Digite o telefone.</Box>)

    // if (!data?.end?.length > 0)
    //     response.push(<Box>Digite o email do colaborador.</Box>)
    
    // if (data.uf.length === 0)
    //     response.push(<Box>Selecione uma Filial.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData