import React, {useState, useEffect, createContext} from "react";
import { useNavigate } from "react-router-dom";

import {api, createSession} from "../services/api";

import { resetPermissions } from "../components/Utils/groups";

import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";



export const AuthContext = createContext();

export const getSession = () => {
    return JSON.parse(sessionStorage.getItem('user'))
}


export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    
    const [user,setUser] = useState(null);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        const recoveredUser = sessionStorage.getItem('user')
        const token = sessionStorage.getItem('token')

        if(recoveredUser && token){
            setUser(JSON.parse(recoveredUser));
            api.defaults.headers.Authorization = `Bearer ${token}`
            resetPermissions()
        }

        setLoading(false);

    }, []);

    const login =  async (username,password) => {

        
        const response = await createSession(username,password)

        if (response.status !== 200){
            return toast.error('Usuário ou senha inválidos')
        }

        const token = response.data.access_token
        const file_size = response.data.file_size

        const decode = jwt_decode(token);
        const grupo = decode['Grupo']
        const user_id = decode['OID']
        const oid_enterprise = decode['OIDEnterprise']
        
        const objLoggedUser = {
            "username":username,
            "id":user_id,
        }

        const loggedUser = objLoggedUser

        sessionStorage.setItem("user", JSON.stringify(decode?.Usuario));
        sessionStorage.setItem("token",token)
        sessionStorage.setItem("user_id",user_id)
        sessionStorage.setItem("grupo",grupo)
        sessionStorage.setItem("oid_enterprise",oid_enterprise)
        sessionStorage.setItem("file_size",file_size)

        api.defaults.headers.Authorization = `Bearer ${token}`
       
        resetPermissions()
        setUser(loggedUser);

        const hashValue = sessionStorage.getItem('hash');

        if(hashValue){
            navigate(`/distribuicao/r?hash=${hashValue}`);
        }else{
            navigate("/painel");
        }

       
       
        
    };

    const logout = () => {
        setUser(null)
        api.defaults.headers.Authorization = null;
        navigate("/login")
    };



    return (
        <AuthContext.Provider
            value={{authenticated: !!user, user, loading, login, logout}}>
                {children}
        </AuthContext.Provider>
    )
};

