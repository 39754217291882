import React, { useState, useContext } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import { DistributionContext } from '.';

const SendBox = ({aro,med,envio,type,calculateTotal}) => {

    const {state,setState} = useContext(DistributionContext)

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(envio)

    const changeToInput = () => {
        setIcon(false)
    }

    const isNumber = (value) => {
        return !isNaN(value - 0) && isFinite(value);
    }

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const check_num = isNumber(inputValue)

        if(check_num){
            setSend(inputValue)
        }
      };

      const changeDict = () => {
        
        let list_dict = ""
        if(type === 'A'){
            list_dict = state.distrA
        } else if (type === 'B'){
            list_dict = state.distrB
        }

      
        let sended = parseInt(send)
        if(send === undefined || send === NaN || send === ""){
            sended = ""
        }

        if(sended === NaN){
            sended = ""
        }
       
        
        for (const dict of list_dict){
            if(dict['aro'] === aro && dict['medida'] === med){
                dict['cargo'] = sended
                dict['envio'] = sended
            }
        }

        setState({distrA:state.distrA,distrB:state.distrB})
        setIcon(true)
        calculateTotal()

      }

    return(
        <>
        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon' sx={{fontWeight:'bold'}}>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true} value={send} onChange={handleChange} onBlur={changeDict} type="text"/>
            </Box>
        }   
        </>
    )
}

export default SendBox;