import React, { useState, useEffect } from 'react'

import { Box, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import { capitalize } from '../../Utils/functions'
import { PhoneInput } from '../../Utils/MaskedInput';


const possibleGroups = [
    {id:1, name:'administrador'},
    {id:2, name:'associado'},
]
    


const EditUsers = ({ data, setData }) => {
    const [reload, setReload] = useState(false)
    
    const addToField = (field) => {
        setData({ ...data, ...field })
    }

    const getGroups = () => {
        const groups = data.groups ? data.groups : []
        if ('list' in groups)
            return groups.list.map(each => {return each.name ? each.name : each})
        return groups.map(each => {return each.name ? each.name : each})
    }

    useEffect(()=>{
        if (!data.groups){
            data.groups = [{name:'associado'}]
            return
        }
            
        if ('list' in data.groups)
            addToField({ 'groups': data.groups.list.map(each => each.name) })
        else
            addToField({ 'groups': data.groups.map(each => each) })
    }, [])

    return (
        <Box className='item-content-modal' >
            {reload && null}
            <TextField
                id="clientname"
                type="text"
                label="Nome do usuário"
                value={data.first_name}
                onChange={(e) => { addToField({ 'first_name': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />

            <TextField
                id="clientlastname"
                type="text"
                label="Sobrenome"
                value={data.last_name}
                onChange={(e) => { addToField({ 'last_name': e.target.value }) }}
                fullWidth={false}
                inputProps={{
                    maxLength: 50
                }}
            />


            <TextField
                id="clientphone"
                type="text"
                label="Telefone"
                value={data.phone_number}
                onChange={(e) => { addToField({ 'phone_number': e.target.value }) }}
                fullWidth={false}
                InputProps={{
                    inputComponent: PhoneInput
                }}
            />

            <TextField
                id="clientemail"
                type="email"
                label="E-mail"
                value={data.email}
                onChange={(e) => { addToField({ 'email': e.target.value }) }}
                fullWidth={true}
                inputProps={{
                    maxLength: 100
                }}
            />

            <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="select-groups-label">Grupos</InputLabel>
                <Select
                    multiple
                    labelId="select-groups-label"
                    id="select-groups"
                    label="Grupos"
                    value={getGroups()}
                    onChange={(e) => { addToField({ 'groups': e.target.value }) }}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {possibleGroups.filter(each => selected.includes(each.name)).map((value) => (
                                <Chip key={value.name} label={capitalize(value.name)} />
                            ))}
                        </Box>
                    )}
                >
                    {possibleGroups.map(each => 
                        <MenuItem value={each.name}>{capitalize(each.name)}</MenuItem>
                    )}
                    
                </Select>
            </FormControl>

        </Box>
    )
}

export default EditUsers