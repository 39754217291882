import React from 'react'
import Box from '@mui/material/Box';

const BoxInfo = ({title,value,colorBack,color}) => {
    return(
        <>
            <Box sx={{display:'flex',flexDirection:'column',width:'9rem'}}>
                <Box sx={{border:'1px solid #ced4da',padding:'0.5rem', fontWeigh:'500', fontSize:'11.5px', textAlign:'center', backgroundColor: colorBack ? colorBack : '#ced4da', color: color ? color : 'black' }}>
                    {title}
                </Box>

                <Box sx={{border:'1px solid #ced4da',padding:'0.5rem',textAlign:'center'}}>
                    {value}
                </Box>
            </Box>
        </>
    )
}

export default BoxInfo;