import React, { useState } from 'react';

const NovoPedido = () => {
    return(
        <>
        A definir.
        </>
    )
}

export default NovoPedido;