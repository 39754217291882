import React, { useContext, useReducer, useEffect, useCallback } from 'react'

import apptheme from '../../theme/default'

import { Box, TextField } from '@mui/material'

import ColorButton from '../../Buttons/ColorButton'

import SaveIcon from '@mui/icons-material/Save';
import { api } from '../../../services/api'
import { defaultReducer } from '../../Utils/reducers';
import { externalComponent } from '../../AppRoutes';
import { toast } from 'react-hot-toast';

const Integrations = () => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(defaultReducer, {
        mercadoPagoToken: '',
        mercadoPagoPublicKey: ''
    })

    const getIntegrations = useCallback(async () => {
        setLoader(true)
        const res = await api.get('/integrations/')
        setLoader(false)
        if (res.status === 200) {
            setState(res.data)
            return
        }
        toast.error('Dados de integração não encontrados.')

    }, [setLoader])

    useEffect(() => {
        getIntegrations()
    }, [getIntegrations])

    const saveData = useCallback(async (value) => {
        setLoader(true)
        const res = await api.patch(`/integrations/${value.id}/`, value)
        setLoader(false)
        if (res.status === 200) {
            setState(res.data)
            toast.success('Dados salvos com sucesso!')
            return
        }
        toast.error('Ocorreu um erro ao salvar os dados.')
    }, [setLoader])

    return (
        <>

            {state.id &&
                <>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <ColorButton
                            className='profile-save-button'
                            onClick={() => saveData(state)}
                            fColor={apptheme.tertiaryColor}
                            sColor={apptheme.primaryColor}>
                            <Box className='flexbox'><SaveIcon /> Salvar</Box>
                        </ColorButton>
                    </Box>
                    <Box sx={{ height: '1rem' }}></Box>
                    <Box className='profile-overflow'>
                        <Box className='item-content-modal' >
                            <TextField
                                id="mercadopagotoken"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Dados de integração MercadoPago"
                                value={state.mercadoPagoToken}
                                onChange={(e) => { setState({ 'mercadoPagoToken': e.target.value }) }}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 255
                                }}
                            />

                            <TextField
                                id="mercadoPagoPublicKey"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Chave pública MercadoPago"
                                value={state.mercadoPagoPublicKey}
                                onChange={(e) => { setState({ 'mercadoPagoPublicKey': e.target.value }) }}
                                fullWidth={false}
                                inputProps={{
                                    maxLength: 255
                                }}
                            />
                        </Box>
                    </Box>
                </>}
        </>
    )
}

export default Integrations