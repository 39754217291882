import React from 'react'
import Box from '@mui/material/Box'

const ConfirmStartStep = () => {
    return(
        <>
        <Box sx={{display:'flex',flexDirection:'column'}}>

            <Box sx={{fontSize:'15px'}}>
                Tem certeza que deseja voltar ao início ? 
            </Box>
        </Box>
        </>
    )
}

export default ConfirmStartStep;