import React, { useEffect, useReducer } from "react"
import { Box, Select, MenuItem, FormControl } from "@mui/material"
import { lockedReducer } from "../../Utils/reducers"
import { capitalize } from "../../Utils/functions"
import { useGridApiContext } from "@mui/x-data-grid"
import MegaAutoComplete from "../../Utils/megautocomplete"

const operations = {
    'icontains': 'Contém',
    'equals': 'Igual'
}

const Internal = ({ filterFields, state, setState, props }) => {
    return (
        <Box sx={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
        <FormControl sx={{ flex: .4 }} size='small' >
            <Select
                id={`selected-${props.field}-stringfilterfield`}
                key={`selected-${props.field}-stringfilterfield`}
                value={state.selector}
                onChange={(e) => {
                    setState({ 'selector': e.target.value })
                    filterFields[props.field].filterField = state.field
                    filterFields[props.field].selectField = e.target.value
                }}
            >
                {Object.entries(operations).map(([key, value]) =>
                    <MenuItem value={key}>{capitalize(value)}</MenuItem>
                )}
            </Select>
        </FormControl>
        <Box sx={{ flex: .6 }}>
            <MegaAutoComplete
                value={state.field}
                onChange={(data) => {
                    setState({ field: data })
                    filterFields[props.field].filterField = data?.label
                    filterFields[props.field].selectField = state.selector
                }}
                noOptionsText={`Digite para procurar ${props.headerName}`}
                timeoutUrl={props.search_url}
                timeoutParam={props.search_result}
                label={props.headerName}
            />
        </Box>
    </Box>
    )
}

export const StringListFilterMobile = (props, parent) => {

    
    const filterFields = parent.filterModel.filterFields

    const [state, setState] = useReducer(lockedReducer, {
        field: filterFields?.[props.field]?.filterField || '',
        selector: filterFields?.[props.field]?.selectField || 'icontains'
    })

    useEffect(() => {
        if (filterFields && props.field in filterFields) {
            setState({ field: filterFields[props.field].filterField || '', selector: filterFields[props.field].selectField || 'icontains' })
            return
        }
    }, [filterFields, props.field])

    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props}/>
    )
}

const StringListFilter = (props) => {

    const apiRef = useGridApiContext();

    const filter = apiRef.current.state.filter
    const filterFields = filter.filterFields

    const [state, setState] = useReducer(lockedReducer, {
        field: filterFields?.[props.field]?.filterField || '',
        selector: filterFields?.[props.field]?.selectField || 'icontains'
    })

    useEffect(() => {
        if (filterFields && props.field in filterFields) {
            setState({ field: filterFields[props.field].filterField || '', selector: filterFields[props.field].selectField || 'icontains' })
            return
        }
    }, [filterFields, props.field])

    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props}/>
    )
}

export default StringListFilter