import { Box } from "@mui/material"

const validateData = (data) => {
  
    const response = []

    // if (!data?.phone?.length > 0)
    //     response.push(<Box>Digite o telefone.</Box>)

    // if (!data?.email?.length > 0)
    //     response.push(<Box>Digite o email do colaborador.</Box>)
    
    // if (!data?.email?.includes('@'))
    //     response.push(<Box>Email não contém caracter @.</Box>)

    // if (data.branch.length === 0)
    //     response.push(<Box>Selecione uma Filial.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData