import React, { useContext, useEffect } from 'react'
import { DistributionContext } from '.';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { distributionRules } from './functionsCore';

const Step1 = () => {

    const {state,setState} = useContext(DistributionContext)

    const changeSelect = (e) => {

        let filial = e.target.value
        const branch = filial.replace('est','')
        let filialVen = filial.replace('est','ven')
        let reservFilial = filial.replace('est','reserv')
        let reservName = reservFilial.split('reserv')
        reservName = `Reserv ${reservName[1].toUpperCase()}`
        
        setState({selectedFilialVen:filialVen,reservBranch:reservFilial,reservBranchName:reservName,selectedFilial:filial,branch:branch})

    }

    useEffect(()=> {
        setState({selectedFilial:"",distrA:[]})
    },[])

    useEffect(()=>{
        
        distributionRules(state.data,state.selectedFilial,state.selectedFilialVen,setState)
    }, [state.selectedFilial])
    
    return (
        <>
         
            <Box sx={{ minWidth: 40 }}>
                <FormControl fullWidth size="small"> 
                    <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Filial"
                    value={state.selectedFilial}
                    onChange={changeSelect}
                    >
                    
                    {
                        Object.entries(state.branches).map(([key, value]) => (
                            <MenuItem key={key} value={value.id}>{value.nome}</MenuItem>
                        ))
                    }

                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default Step1;