import React, { useContext } from 'react'
import DefaultModal from '../Utils/DefaultModal';
import { DistributionContext } from '.';
import BodyModalPendency from './bodyModalPendency';
import { distributionInitialState } from '.';

const ModalPendency = () => {

    const {state,setState} = useContext(DistributionContext)
    
    const closeModal = () => {
        const newStep = 0
        setState({...distributionInitialState,data:state.data,dataToResetDist:state.dataToResetDist,branches:state.branches,step:newStep,modalState:false})
        setState({modalPendency:false})
    }
      
    return(
        <>
          <DefaultModal dialogProps={{maxWidth:'xm'}} open={state.modalPendency} handleClose={closeModal} content={<BodyModalPendency closeModalPendency={closeModal} />}
            title={'Pendentes'}/>
        </>
    )
}

export default ModalPendency;