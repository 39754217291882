import React, { useContext, useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { api } from '../../services/api';
import { externalComponent } from '../AppRoutes';
import { DistributionContext } from '.';
import DefaultModal from '../Utils/DefaultModal';
import Visualization from './visualization';
import ColorButton from '../Buttons/ColorButton';
import toast from 'react-hot-toast';
import SugestionVisualization from './sugestionVisualization';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import ModalText from './modalText';
import ModalStep2 from './modalstep2';
import Tooltip from '@mui/material/Tooltip';
import { distributionRules,sortTables, formatDate } from './functionsCore';
import { distributionInitialState } from '.';


const BodyModalPendency = ({closeModalPendency}) => {

    function convertISODate(isodate) {

      if (!isodate) {
        return '';
      }

        const date = new Date(isodate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }


    const {setLoader } = useContext(externalComponent)
    const {state,setState} = useContext(DistributionContext)
    
    const [rows,setRows] = useState({"datacriacao":""})
    const [modalState,setModalState] = useState(false)
    const [modalConfirm,setModalConfirm] = useState(false)
    const [hashValue,setHashValue] = useState(false)
    const [confirmOrder,setConfirmOrder] = useState(false)
    const [titleName,setTitleName] = useState("")

    const closeModal = () => {
      setModalState(false)
    }

    const closeModalConfirm = () => {
      setModalConfirm(false)
    }

    const closeModalConfirmOrder = () => {
      setConfirmOrder(false)
    }

    const openModalConfirmOrder = () => {
      const lenTables = state.consolidatedStep4.length
        
        let error = false
        for (let i = 1; i <= lenTables; i++) {
            
            let lines = state.consolidatedStep4.filter(each => each[`table${i}`])[0][`table${i}`]['lines']
            
            if(lines.length === 0){
                error = true
                
            }

        }

        if(error === true){
            toast.error('Todas os caminhões devem ter cargas para finalizar!')
        }else{
          setConfirmOrder(true) 
        }
    }

    const getDistribution = async () => {
      const body = {
          "dt_inicial": formatDate(state.dtInicial),
          "dt_final": formatDate(state.dtFinal)
      }
    
      setLoader(true)
      const res = await api.post('/distribution/',body)
      if (res.status === 200){
          const sorted_table = sortTables(res.data)
          
          setState({...distributionInitialState,data:sorted_table,dataToResetDist:JSON.stringify(sorted_table),branches:state.branches})
          
      }
      setLoader(false)
  }

    const createOrder = async () => {
      const oid_enterprise = sessionStorage.getItem("oid_enterprise")
      const body = {
        "hash":hashValue,
        "tables": state.consolidatedStep4,
        "oid_enterprise":oid_enterprise 
      }

      setLoader(true)
        const res = await api.post('/criapedido/',body)
      setLoader(false)
      
      if (res.status === 200){
        toast.success('Pedido criado com sucesso !')
      
        if( state.dtInicial !== "" || state.dtFinal !== ""){
          getDistribution()
        }

        
        closeModal()
        closeModalPendency()
      }
    }

    const openModalStep2 = () => {
      if(state.distrA.length === 0 && state.distrB.length === 0){
        toast.error('Faça a consulta inicial primeiro.')
      }else{
        setState({modalStep2:true})
      }
      
    }

    const closeModalStep2 = () => {
      setState({modalStep2:false})
    }

    const ButtonUpdate = () => {
      return(
          <Box>
              <ColorButton style={{marginRight:'20px'}} className='realpadding' onClick={openModalStep2}>Consultar Estoques</ColorButton>

              <ColorButton  className='realpadding' onClick={openModalConfirmOrder}>Criar pedido</ColorButton>
          </Box>
      )
  }


  const confirmDeactivated = async () => {
    const body = {
      "hash":hashValue,
    }

    setLoader(true)
      const res = await api.post('/desativar/carga/',body)
    setLoader(false)

    if (res.status === 200){
      toast.success('Carga desativada com sucesso !')
      getPendencies()
      closeModalConfirm()
    }

    const body2 = {
      "dt_inicial": formatDate(state.dtInicial),
      "dt_final": formatDate(state.dtFinal)
  }

    setLoader(true)
    const res2 = await api.post('/distribution/',body2)
    if (res2.status === 200){
        const sorted_table = sortTables(res2.data)
        
     setState({...distributionInitialState,data:sorted_table,dataToResetDist:JSON.stringify(sorted_table),branches:state.branches})
        
    }
    setLoader(false)
  }

  const ButtonConfirm = () => {
    return(
        <Box>
            <ColorButton  className='realpadding' onClick={confirmDeactivated}>Sim</ColorButton>
        </Box>
    )
}

const ButtonConfirmOrder = () => {
  return(
      <Box>
          <ColorButton  className='realpadding' onClick={createOrder}>Sim</ColorButton>
      </Box>
  )
}
    
    const changeHash = async (hash) => {
        setHashValue(hash)
        setLoader(true)
          const res = await api.get(`/consulta/distribuicao/${hash}/`)
        setLoader(false)
        if (res.status === 200) {
            const response = res.data
            const tables = response.tables
            const branch = response.branch
            const venBranch = branch.replace('est','ven')
            let reservFilial = branch.replace('est','reserv')
            let reservName = reservFilial.split('reserv')
            reservName = `Reserv ${reservName[1].toUpperCase()}`
            const branchName = Object.entries(state.branches) .filter(([_, value]) => value.id === branch).map(([key, value]) => value.nome)[0]
            const branchAcronym = branch.replace('est','')
            const title = `Carga - ${branchName} (5)`
            distributionRules(state.data,branch,venBranch,setState)
            setTitleName(title)
            setState({consolidatedStep4:tables,dataToReset:JSON.stringify(tables),reservBranch:reservFilial,reservBranchName:reservName,selectedFilial:branch,selectedFilialVen:venBranch,branch:branchAcronym})
            setModalState(true)
            return
        }
    }

    const getPendencies = async () => {

        setLoader(true)
            const res = await api.get(`/log/pendente/`)
        setLoader(false)
        if (res.status === 200) {
            const data = res.data
            setRows(data)
            return
        }
    }

    const copyLink = (link) => {
      navigator.clipboard.writeText(link).then(() => {
        toast.success('Link copiado para a área de transferência!');
      });
    };

    const openModalConfirmDeactivated = (hash) => {
      setHashValue(hash)
      setModalConfirm(true)
    }

    useEffect(() => {
        getPendencies()
    },[])


    const columnsMain = [
    
        {
            field: 'datacriacao',
            headerName: 'Data Criação',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {convertISODate(value)}
              </Box>
            )
        },
        {
            field: 'por',
            headerName: 'Criado por',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'para',
            headerName: 'Enviado para',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'visualizado',
            headerName: 'Visualizado',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {
                value ?
                 <CheckCircleIcon sx={{color:'#00c61f', fontSize:'1.3rem'}} />
                 :
                 <CancelIcon sx={{color:'#c60000', fontSize:'1.3rem'}}/>
               }
              </Box>
            )
        },
        {
            field: 'visualizadoem',
            headerName: 'Visualizado em',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {convertISODate(value)}
              </Box>
            )
        },
        {
          field: 'alteradoem',
          headerName: 'Alterado em',
          flex: 0.4,
          renderCell: ({value}) => (
            <Box sx={{padding:'0px 10px'}}>
             {convertISODate(value)}
            </Box>
          )
      },
        {
            field: 'acoes',
            headerName: 'Ações',
            renderCell: (params) => (
    
              <Box sx={{padding:'0px 10px'}}>

                <Tooltip title='Visualiza pedido'>
                  <VisibilityIcon className='actionIcon' onClick={() => changeHash(params.row.hash)} sx={{fontSize:'1.3rem'}}/>
                </Tooltip>

                <Tooltip title='Desativa pedido'>
                  <DeleteIcon className='actionIcon'onClick={() => openModalConfirmDeactivated(params.row.hash)}  sx={{fontSize:'1.1rem'}}/>
                </Tooltip>

                <Tooltip title='Link da distribuição'>
                  <LinkIcon className='actionIcon' onClick={() => copyLink(params.row.link_dist)}  sx={{fontSize:'1.1rem'}}/>
                </Tooltip>
              </Box>
            )
          }

    ]

    const VisualizationBody = () => {
      return(
        <Box>
            <Visualization hash={hashValue}/>
            
            <SugestionVisualization hash={hashValue}/>
        </Box>
    )
    }

    return(
        <>
        <DefaultModal dialogProps={{maxWidth:"xl"}} open={state.modalStep2} handleClose={closeModalStep2} content={<ModalStep2/>}
          title={' Estoque '}/>

        <DefaultModal dialogProps={{maxWidth:"xl"}} open={modalState} handleClose={closeModal} 
            title={titleName} content={VisualizationBody()} action={<ButtonUpdate/>} />

      <DefaultModal dialogProps={{maxWidth:"xs"}} open={modalConfirm} handleClose={closeModalConfirm} 
                  title={' Confirmação '} content={<ModalText text={'Tem certeza que deseja desativar ?'}/>} action={<ButtonConfirm/>} />

      <DefaultModal dialogProps={{maxWidth:"xs"}} open={confirmOrder} handleClose={closeModalConfirmOrder} 
                  title={' Confirmação '} content={<ModalText text={'Tem certeza que deseja criar um pedido ?'}/>} action={<ButtonConfirmOrder/>} />

            <Box sx={{display:'flex',width:'100%',height:'30rem'}} >
                <DataGrid 
                  className='alterned_grid'
                  rows={rows}                                                                                                                                                                                                                                                                                                                                                                                                                       
                  columns={columnsMain}
                  pagination={false}
                  density='compact'
                  pageSize={5}
                  sx={{
                  maxHeight:"30rem",
                  }}
                  rowHeight={27}
                />
            </Box>

        </>
    )
}

export default BodyModalPendency;