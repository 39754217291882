import React, { useEffect, useContext,createContext, useReducer,useState } from 'react';
import { defaultReducer } from '../Utils/reducers'
import DatagridRepresentative from './datagridRepresentative';
import { externalComponent } from '../AppRoutes';
import { api } from '../../services/api';
import SugestionRepresentative from './sugestionRepresentative';
import SearchCodpro from './SearchCodpro';
import { sortTables } from './functionsCore';
import InformativeNotice from './informativeNotice';
import ColorButton from '../Buttons/ColorButton';
import Box from '@mui/material/Box';

export const RepresentativeContext = createContext()

const DistributionR = ({hash}) => {
   
    const { setLoader } = useContext(externalComponent)
    const [status,setStatus] = useState(true)
    const [detail,setDetail] = useState("")
    const [state, setState] = useReducer(defaultReducer, {
        
      data:[], sugestions:{}, dataToReset:[], alertSugestion:false,changedTables:false,
      branch:"", updateCodpro:false,hash:hash
        
    })

    const getRedirect = async () => {
    
        const res = await api.get(`/consulta/distribuicao/${hash}/`)
       
        if (res.status === 200) {
           
            const response = res.data
            if(response.status_code === 404){
                setStatus(false)
                setDetail(response.detail)
                return
            }

            const tables = response.tables
            const selectedFilial = response.branch
            const branch = selectedFilial.replace('est','')
            setState({data:tables,dataToReset:JSON.stringify(tables),alertSugestion:true,branch:branch,selectedFilial:selectedFilial})
            return
        }
    }

    useEffect(() => {
        setLoader(true)
        getRedirect()
    },[])

    const changeList = (dict) => {
        let lines = state.data[0]['table1']['lines']
        state.data[0]['table1']['lines'] = [...lines,dict]
        setState({data:state.data})
        setState({changedTables:!state.changedTables})
    }

    const checkTable = (dict) => {
        let lines = state.data[0]['table1']['lines']
        const check = lines.filter(each => each.codpro === dict['codpro'])
        if(JSON.stringify(check) === '[]'){
            return false
        }else{
            return true
        }
    }

    const updateCodproInfo = () => {
        setState({updateCodpro:!state.updateCodpro})
    }

    return(
        <>
            

            {
            status ?
                <Box sx={{display:'flex',flexDirection:'row',gap:'1rem', alignItems:'flex-end'}}>
                    <SearchCodpro changeList={changeList} checkTable={checkTable} state={state} setState={setState} data={state.data} type={'representative'} />

                    <Box>
                        <ColorButton onClick={updateCodproInfo}>Atualizar</ColorButton>
                    </Box>
                    
                </Box>
            :
            
                detail ?
                 <InformativeNotice pedido={detail.pedidos} user={detail.Usuario}/>
                :
                <></>
            }
            

            {
            status ?
            state.data?.map((item, index) => {
                        const num = index + 1;
                        const keyTB = `table${num}`
                        const current = item[keyTB]

                        let num_m3 = 0
                        let total_cargo = 0
                        let total_peso = 0

                        for(const each of current.lines){

                            let convertedNumM3 = parseFloat(each.m3.replace(',','.')) * parseInt(each.cargo)
                            let convertedNumPeso = parseFloat(each.peso) * parseInt(each.cargo)

                            num_m3 = convertedNumM3 + num_m3
                            total_peso = convertedNumPeso+ total_peso
                            total_cargo = parseInt(total_cargo) + parseInt(each.cargo)


                        }
                        num_m3 = num_m3.toFixed(2)
                        total_peso = total_peso.toFixed(2)

                        const truck_size = item[keyTB]['truck_size']
                        item[keyTB]['total_m3'] = num_m3

                        const linesTruck = current.lines
           
                        const sorted_lines = sortTables(linesTruck)

                        return (
                            <RepresentativeContext.Provider value={{state,setState}}>
                                <DatagridRepresentative key={`table-${num}`} index={num} truck_size={truck_size} lines={sorted_lines} table={keyTB} m3={num_m3} peso={total_peso} envio={total_cargo}/>
                            </RepresentativeContext.Provider>
                        );
                  })

             :
             <></>
            }

            {
            status ?
            <RepresentativeContext.Provider value={{state,setState}}>
                        <SugestionRepresentative hash={hash}/>
            </RepresentativeContext.Provider>
            :
            <></>
            }
        </>
    )
}

export default DistributionR;