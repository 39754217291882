import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import { DistributionContext } from '.';
import DefaultModal from '../Utils/DefaultModal';
import ConfirmModal from './confirmModal';
import ColorButton from '../Buttons/ColorButton';
import toast from 'react-hot-toast';

const NumTruck = ({}) => {
    const {state,setState} = useContext(DistributionContext)
    
    const [modalState,setModalState] = useState(false)

    const closeModal = () => {
        setModalState(false)
    }

    const [typeFunc,setTypeFunc] = useState(true)
    const [strType,setStrType] = useState('')

    const addTable = (num) => {

        const keyName = `table${num}`

        const new_dict = {
               
                [keyName]:{
                    "truck_size":118,
                    "total_m3":"",
                    "lines":[]
                }
            }
        

        state.consolidatedStep4.push(new_dict)
        setState({consolidatedStep4:state.consolidatedStep4})
        setModalState(false)
    }

    const removeTable = (num) => {
        const removeTable = `table${num}`
        const consolidated = state.consolidatedStep4
       
        const removeLines = consolidated.filter(each => each[removeTable])[0][removeTable].lines
        const newDict = consolidated.filter((item) => !item.hasOwnProperty(removeTable));

        if(removeLines.length === 0){
            setState({consolidatedStep4:newDict})
        }else{
            const nextNum = num - 1
            const nextTable = `table${nextNum}`
            let currentLines = []

            for(const each of newDict){

                if(each[nextTable]){

                    currentLines = each[nextTable].lines

                    for (const obj_line of removeLines){
                        let check = currentLines.filter(obj => obj.id === obj_line.id)
                        
                        if(check.length === 0){
            
                            currentLines = [...currentLines,obj_line]
                    
                        }else{
                            const newValue = check[0].cargo + obj_line.cargo
                            check[0].cargo = newValue              
                        }
                    }

                }

            }
            newDict.filter(each => each[nextTable])[0][nextTable].lines = currentLines
            
            setState({consolidatedStep4:newDict})
            setState({changedTables:!state.changedTables})
            
        }
        closeModal()
        
    }

    const openDialog = (type) => {

        if(type === true){
            setStrType('acrescentar')
        }else{

            if(state.numTruck === 1){
                toast.error('O caminhão inicial não pode ser removido !')
                return
            }

            setStrType('remover')
        }

        setTypeFunc(type)
        setModalState(true)
    }

    const addOrMinus = () => {
        let num = state.numTruck
        if(typeFunc === true){
            num = num + 1
            addTable(num)
        }else{
            if(num > 1){
                removeTable(num)
                num = num - 1
            }
        }
        
        setState({numTruck:num})
        setState({sendStep4:!state.sendStep4})
    }

    const Buttons = () => {
        return(
            <Box>
                <ColorButton  className='realpadding' onClick={addOrMinus} >Sim</ColorButton>
            </Box>
        )
    }

    const updateStep4 = () => {
        setState({updateStep4:!state.updateStep4})
    }

    return(
        <>
            <DefaultModal dialogProps={{maxWidth:"xs"}} open={modalState} handleClose={closeModal} content={<ConfirmModal type={strType}/>}
            title={' Confirmação '} action={<Buttons/>} />

            <Box sx={{display:'flex', flexDirection: 'row',gap:'1.7rem'}}>
                <Box sx={{display:'flex',gap:'1rem', flex:'0.7'}}>
                    <ColorButton  className='realpadding' onClick={() => openDialog(true)} >Adicionar caminhão </ColorButton>
                    <ColorButton  className='realpadding' onClick={() => openDialog(false)} >Remover caminhão </ColorButton>
                </Box>

                <Box sx={{display:'flex', flex:'0.3'}}>
                    <ColorButton  className='realpadding' onClick={updateStep4} >Atualizar </ColorButton>
                </Box> 
            </Box>
        </>
    )
}

export default NumTruck;