import React, { useState } from 'react'
import { useGridApiContext } from '@mui/x-data-grid';
import { Box, Switch, FormControlLabel, TextField } from '@mui/material';
import { alpha } from '@mui/material';
import './styles.css'
import ColorButton from '../Buttons/ColorButton';
import apptheme from '../theme/default';

const Columns = () => {
    const apiRef = useGridApiContext();
    const columns = apiRef.current.state.columns.lookup
    const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel
    const setState = apiRef.current.setState
    const [, setReload] = useState(false)
    const [filterFields, setFilterFields] = useState('')
    const reloadState = () => setReload(p => !p)
    return (
        <Box className='table-column-popover' >
            <TextField
                sx={{
                    margin: '0 0.5rem',
                    '& .Mui-focused': {
                        color: apptheme.primaryColor
                    },
                    '& .MuiInput-root::after': {
                        borderBottom: `2px solid ${apptheme.primaryColor}`
                    }
                }}
                placeholder='Título da coluna'
                variant='standard'
                size='small'
                label='Localizar coluna'
                value={filterFields}
                onChange={(e) => setFilterFields(e.target.value)}
            />
            <Box className='table-column-list'>
                {Object.entries(columns).filter(([_, value]) => value.headerName.includes(filterFields)).map(([key, value]) => (
                    <FormControlLabel size='small' control={<Switch
                        key={key}
                        size='small'
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: apptheme.primaryColor,
                                '&:hover': {
                                    backgroundColor: alpha(apptheme.primaryColor, .1),
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: apptheme.primaryColor,
                            },
                        }}
                        checked={key in columnVisibilityModel ? columnVisibilityModel[key] : true}
                        onClick={() => {
                            const newColumns = apiRef.current.state.columns
                            newColumns.columnVisibilityModel = { ...columnVisibilityModel, [key]: key in columnVisibilityModel ? !columnVisibilityModel[key] : false }
                            setState({ ...apiRef.current.state, columns: { ...newColumns } })
                            reloadState()
                        }}

                    />}
                        label={value.headerName}
                    />
                ))}
            </Box>
            <Box className='table-column-popover-footer'>
                <ColorButton
                    reverse={true}
                    onClick={() => {
                        const newColumns = apiRef.current.state.columns
                        newColumns.columnVisibilityModel = {}
                        setState({ ...apiRef.current.state, columns: { ...newColumns } })
                        reloadState()
                    }}>Mostrar todas</ColorButton>
                <ColorButton
                    reverse={true}
                    onClick={() => {
                        const newColumns = apiRef.current.state.columns
                        newColumns.columnVisibilityModel = Object.fromEntries(Object.keys(columns).map(each => [each, false]))
                        setState({ ...apiRef.current.state, columns: { ...newColumns } })
                        reloadState()
                    }}>Esconder todas</ColorButton>
            </Box>
        </Box>
    )
}

export default Columns