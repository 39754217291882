import React, { useState } from 'react'
import ColorButton from '../Buttons/ColorButton'
import apptheme from '../theme/default'
import Users from './Users'
import { Box } from '@mui/material'
import './styles.css'

import ReplyIcon from '@mui/icons-material/Reply';
import DefButton from '../Buttons/defbutton'

import GroupIcon from '@mui/icons-material/Group';
import Person2Icon from '@mui/icons-material/Person2';
import EmailIcon from '@mui/icons-material/Email';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import { isAdmin } from '../Utils/groups'
import Profile from './Profile'
import Email from './Email'
import Integrations from './Integrations'

const MenuList = () => ({
    'users': {
        title: 'Configurar Usuários',
        icon: (<GroupIcon />),
        obj: (<Users />),
        permission: (isAdmin)
    },
    'profile': {
        title: 'Perfil',
        icon: (<Person2Icon />),
        obj: (<Profile />),
        permission: true
    },
    'email': {
        title: 'Email',
        icon: (<EmailIcon />),
        obj: (<Email />),
        permission: (isAdmin)
    },
    'integrations': {
        title: 'Integrações',
        icon: (<LeakAddIcon />),
        obj: (<Integrations />),
        permission: (isAdmin)
    },
})

const Settings = () => {
    const [selectedTab, setSelectedTab] = useState()

    const menulist = MenuList()

    return (
        <>
            {!selectedTab ? <Box className='gridBox'>
                {Object.entries(menulist).filter(([key, value]) => value.permission).map(([key, value]) => (
                    <ColorButton
                        onClick={() => setSelectedTab(key)}
                        sx={{ height: '8rem' }}
                        fColor={apptheme.primaryColor}
                        sColor={apptheme.tertiaryColor}>
                        <Box className='flexbox'>
                            {value.icon}
                            {value.title}
                        </Box>
                    </ColorButton>

                ))}</Box> :
                <>
                    <Box className='settings-back-button'>
                        <DefButton
                            onClick={() => setSelectedTab()}
                        >
                            <ReplyIcon />
                        </DefButton>
                    </Box>
                    {MenuList()[selectedTab].obj}
                </>
            }

        </>
    )
}

export default Settings