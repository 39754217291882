import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';

const RemoveIcon = ({num,infos,sugestions,setState}) => {

    function removeItemByCodpro() {

        const codpro = infos.row.codpro
        const table = `table${num}`;

        if (sugestions[codpro] && sugestions[codpro][table]) {
          delete sugestions[codpro][table];
        }

        setState({sugestions:sugestions})
        
      }

    return(
        <>
          
        <ListItem
        secondaryAction={
            <IconButton edge="end" aria-label="delete">
                <DeleteIcon  onClick={removeItemByCodpro} />
            </IconButton>
            }
            >
        </ListItem>
           
          
        </>
    )
}

export default RemoveIcon;
