import React  from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PasswordBtn from './passwordbtn';
import { useContext, useState } from 'react';
import Loader from "../Loader/loader";
import { ThemeProvider, useTheme } from '@mui/material/styles';
import {customTheme} from '../theme/textfieldTheme';

import { AuthContext } from '../../contexts/auth'; 

const InputsLogin = () => {

    const [loading, setLoading] = useState(false)
    const { login } = useContext
    (AuthContext);
    
    const [username,setUser] = useState("")
    const [password,setPassword] = useState("")

    const submitHandler = async (e) => {
        e.preventDefault()

        setLoading(true)
        await login(username,password)
        setLoading(false)
    }

    const changeUserHandler = (e) => {
        setUser(e.target.value)
    }

    const changePassowrdHandler = (e) => {
        setPassword(e.target.value)
    }

    const outerTheme = useTheme();

    return(
      
        <section>
            <Loader external show={loading} />
            <form onSubmit={submitHandler}>
                <div className="items-center">

                <ThemeProvider theme={customTheme(outerTheme)}>
                    <div>
                        <TextField 
                            id="txtUserName"
                            margin="normal" 
                            required
                            fullWidth
                            label="Usuário" 
                            name="usuario"
                            type="text"
                            value={username}
                            onChange={changeUserHandler}
                        />
                    </div>
                    
                    <div className="items-top">
                        <PasswordBtn passProp={password} changePass={changePassowrdHandler}/>
                    </div>
                </ThemeProvider>
                
                    <div className="items-top">
                        <Button
                            style={{ 
                                backgroundColor: "rgb(236 27 38)"
                            }}
                            type="submit" 
                            variant="contained" 
                            fullWidth
                            color="secondary" 
                            size="medium" 
                            className="mb-3 mb-md-4 md-4"
                            >
                            Entrar
                        </Button>
                    </div>
                    
                </div>
            </form>
        </section>
   
    )
};

export default InputsLogin;