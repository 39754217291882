import AppRoutes from "./components/AppRoutes";
import React  from 'react';

console.disableYellowBox = true;

function App() {

    return(
      <div>
        <AppRoutes/>
      </div>
      );
  }

 


export default App;
