import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import { DistributionContext } from '.';

const SendBoxVisualization = ({envio,table,codpro}) => {

    const {state,setState} = useContext(DistributionContext)

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(envio)

    const getUpdatedSend = () => {
    
        for (const each of state.consolidatedStep4){
            if(each[table]){
                try {
                    const lines = each[table]['lines']
                    const cargo = lines.filter(each => each.codpro === codpro)[0]['cargo']
                    setSend(cargo)
                  } catch (error) {
                    
                  }
            }
        }
    }

    useEffect(() => {
        getUpdatedSend()
    },[state.changedTables])

    const alterDictLines = () => {
        for ( const each of state.consolidatedStep4 ){
        
            if(each[table]){
              const lines =  each[table]['lines']
              for (const line of lines){
                if(line['codpro'] === codpro){
                    line['cargo'] = send
                }
              }
    
            }
          }
          setState({consolidatedStep4:state.consolidatedStep4})
    }


    const changeToInput = () => {
        setIcon(false)
    }

    const isNumber = (value) => {
        return !isNaN(value - 0) && isFinite(value);
    }

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const check_num = isNumber(inputValue)
        if(check_num){
            setSend(inputValue)
        }
      };

    const changeDict = () => {
        alterDictLines()
        setIcon(true)

      }

    return(
        <>
        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon'>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true} value={send} onChange={handleChange} onBlur={changeDict} type="text"/>
            </Box>
        }   
        </>
    )
}

export default SendBoxVisualization;