import React, { useContext, useState, useEffect } from 'react'
import { DistributionContext } from '.';
import { externalComponent } from '../AppRoutes';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Checkbox from '@mui/material/Checkbox';
import DefaultModal from '../Utils/DefaultModal';
import BoxInfo from './boxInfo';
import BoxSizeTruck from './boxSizeTruck';
import ModalTransfer from './modalTransfer';
import { toast } from 'react-hot-toast';
import SendBoxStep4 from './sendBoxStep4';
import ModalText from './modalText';
import ColorButton from '../Buttons/ColorButton';
import { api } from '../../services/api';
import { updateCodproInfo } from './functionsCore';
import MessageStep4 from './messageStep4';

const DatagridStep4 = ({index,lines,table,m3,peso,envio,truck_size}) => {

    const { setLoader } = useContext(externalComponent)
    const {state,setState} = useContext(DistributionContext)
    const [modalStatus,setModalState] = useState(false)
    const [rowToTransfer,setRowToTransfer] = useState("")
    const [modalDelete,setModalDelete] = useState(false)
    const [rowToDelete,setRowToDelete] = useState("")
    const [message,setMessage] = useState("")

    const closeModal = () => {
      setModalState(false)
    }

    const transferFunc = (row) => {
      let tables = state.consolidatedStep4
      
      if(tables.length > 1){
        setRowToTransfer(row)
        setModalState(true)
      }else{
        toast.error('Não há caminhões para transferência.')
      }

      
    }

    const changeChecked = (e,row) => {
      const checked = e.target.checked
      const rowId = row.id

      for(const each of state.consolidatedStep4){

        if(each[table]){
          const lines = each[table].lines

          for(const line of lines){

            if(line.id === rowId){
              line['checked'] = String(checked) 
            }

          }
      }

      
      }

    }

    const confirmDelete = () => {
    

      for ( const each of state.consolidatedStep4 ){
        
        if(each[table]){
          const lines =  each[table]['lines']
          const filteredLines = lines.filter(item => item.codpro !== rowToDelete);
          each[table]['lines'] = filteredLines

        }
      }

      setState({consolidatedStep4:state.consolidatedStep4})
      closeModalDelete()
  }

    const closeModalDelete = () => {
      setModalDelete(false)
    }
  
    const deleteRow = (row) => {
      setRowToDelete(row.codpro)
      setModalDelete(true)
    }

    const buttonConfirmDelete = () => {
      return(
      <Box>
          <ColorButton onClick={confirmDelete} >Sim </ColorButton>
      </Box>
      )
  }

    const columnsMain = [
    
        {
            field: 'aro',
            headerName: 'Aro',
            flex: 0.3,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px', fontWeight: 'bold'}}>
               {value}
              </Box>
            )
        },
        {
            field: 'medida',
            headerName: 'Medida',
            flex: 0.4,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px' , fontWeight: 'bold'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'codpro',
            headerName: 'Codpro',
            flex: 0.3,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'descr',
            headerName: 'Descr',
            flex: 1.7,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'peso',
            headerName: 'Peso',
            flex: 0.2,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'm3',
            headerName: 'm³',
            flex: 0.3,
            renderCell: ({value}) => (
              <Box sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: `est${state.branch}`,
            headerName: `Est ${state.branch.toUpperCase()}`,
            flex: 0.3,
            renderCell: ({value}) => (
              <Box className='est-tables' sx={{padding:'0px 10px',fontWeight:'bold'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'dispbks',
            headerName: 'BKS Disp',
            flex: 0.3,
            renderCell: ({value}) => (
              <Box className='dep-bks' sx={{padding:'0px 10px'}}>
               {value}
              </Box>
            )
          },
          {
            field: 'cargo',
            headerName: 'Envio',
            flex: 0.5,
            renderCell: (params) => (
              <Box sx={{padding:'0px 25px'}}>
                <SendBoxStep4 cargo={params.row.cargo} codpro={params.row.codpro} table={table} />
              </Box>
            )
          },
          {
            field: 'acoes',
            headerName: 'Ações',
            flex: 0.4,
            renderCell: (params) => (
              <Box sx={{padding:'0px 0px'}}>
                <ImportExportIcon className='actionIcon' sx={{fontSize:'1.3rem'}} onClick={() => transferFunc(params.row)} />
                <Checkbox onClick={(event) => changeChecked(event,params.row)} />
                <DeleteForeverIcon onClick={() => deleteRow(params.row)} className='actionIcon' sx={{fontSize:'1.3rem'}}/>
              </Box>
            )
          },

    ]

 
    const getCodproInfos = async () => {
      const branch = state.branch
      const codpros = lines.map(each => each['codpro'])

      const body = {
        "branch":branch,
        "codpros":codpros
      }

      setLoader(true)
      const res = await api.post(`/codproinfo/`, body)
      if (res.status === 200) {
          const data = res.data
          updateCodproInfo(data,lines)
      }
      setLoader(false)
      setState({sendStep4:!state.sendStep4})

    }

    useEffect(() => {
      getCodproInfos()
    },[state.updateStep4])

    const changeMessage = (e) => {
      const value = e.target.value
      setMessage(value)
    }

    return(
        <>
        <br/>

        <DefaultModal dialogProps={{maxWidth:'xs'}} open={modalDelete} handleClose={closeModalDelete} content={<ModalText text={'Tem certeza que deseja deletar esta linha ?'}/>} action={buttonConfirmDelete()}
            title={' Deletar linha '}/>

        <DefaultModal dialogProps={{maxWidth:'xs'}} open={modalStatus} handleClose={closeModal} content={<ModalTransfer table={table} row={rowToTransfer} handleClose={closeModal}/>} 
            title={' Transferência de carga '} />

            <Box sx={{display:'flex',flexDirection:'row',gap:'1rem', justifyContent:'space-between'}} >

                <Box sx={{flex:'0.8',display:'flex',height:'25rem'}} >
                    <DataGrid 
                                className='alterned_grid'
                                rows={lines}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                columns={columnsMain}
                                pagination={false}
                                density='compact'
                                pageSize={5}
                                sx={{
                                maxHeight:"30rem",
                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                  justifyContent: 'center',
                                },
                                }}
                                rowHeight={state.rowHeight}
                    />
                </Box>

                <Box sx={{display:'flex',flexDirection:'column', flex:'0.2',justifyContent: index > 1 ? 'flex-start' : 'space-between', gap: index > 1 ? '1rem' : '0rem'}}>
                  
                  <Box sx={{display:'flex',flexDirection:'row',gap:'1rem'}}>

                    <BoxInfo title={'Volume total (m³)'} value={m3} colorBack={'#c60000'} color={'white'} />

                    <BoxSizeTruck truck_size={truck_size} title={'Volume do caminhão (m³)'} table={table} colorBack={'#c60000'} color={'white'}/>

                  </Box>

                  <Box sx={{display:'flex',flexDirection:'row', gap:'1rem'}}>

                    <BoxInfo title={'Peso total (kg)'} value={peso} colorBack={'#c60000'} color={'white'} />

                    <BoxInfo title={'Total de pneus'} value={envio} colorBack={'#c60000'} color={'white'} />

                  </Box>

                  {
                    index > 1 ?
                    <></>
                    :
                    
                    <MessageStep4 setState={setState}/>
                  }
              
                </Box>

            </Box>
        </>
    )
}

export default DatagridStep4;