import React, { useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel';

import apptheme from '../../theme/default';


const ConfirmationDialog = ({ open, title, subscripted, handleClose, submit }) => {


    return (
        <>
            {open &&
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth='xs'
                    className='item-dialog'
                >
                    <DialogTitle>{title}</DialogTitle>
                    <Divider />
                    {subscripted &&
                    <>
                    <DialogContent>{subscripted}</DialogContent>
                    <Divider />
                    </>}
                    <DialogActions>
                        <Button onClick={handleClose} sx={{ color: apptheme.tertiaryColor }}>Cancelar</Button>
                        <Button onClick={submit} sx={{ color: apptheme.quaternaryColor }}>Confirmar</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )

}

export default ConfirmationDialog