import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';

const SendBoxSearch = ({row}) => {

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(row.cargo)

    const changeToInput = () => {
        setIcon(false)
    }

    const isNumber = (value) => {
        return !isNaN(value - 0) && isFinite(value);
    }

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const check_num = isNumber(inputValue)
        if(check_num){
            setSend(inputValue)
        }
    }

    const changeDict = () => {
        row['cargo'] = parseInt(send)
        setIcon(true)
      }


    return(
        <>

        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon'>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true} value={send} onChange={handleChange} onBlur={changeDict} type="text"/>
            </Box>
        }

        </>
    )

}

export default SendBoxSearch;