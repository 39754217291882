

 const apptheme = {
    primaryColor: '#c60000',
    secondaryColor : '#FFFFFF',
    tertiaryColor : '#8a0000',
    quaternaryColor : '#ec9c05',
    quintenaryColor : '#44c485',
    senaryColor : '#339263',
    septenary : '#666666'

} 

export default apptheme
