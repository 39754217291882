import React,{useState} from 'react';

import { Box } from '@mui/material';


const OrderFrame = ({num,emailDesc}) => {

    const defaultBackend = process.env.REACT_APP_URL_PLATESYS

    const [link,setLink] = useState(`${defaultBackend}pedido/${num}`)
  

    return(
        <>
        <Box>
           <p style={{textWrap:'wrap',color:'#d86600', fontWeight: 'bold'}}>Obs: {emailDesc}</p>
        </Box>

        <Box sx={{display:'flex',justifyContent:'center',height:'95%'}}>
            <iframe src={link} frameborder="0" width="100%" height="100%"></iframe>
        </Box>
        </>
    )
}

export default OrderFrame;