import React, { useContext, useState, useEffect } from 'react'
import { DistributionContext } from '.';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ColorButton from '../Buttons/ColorButton';
import toast from 'react-hot-toast';
import { transferRow } from './functionsCore';

const ModalTransfer = ({table,row,handleClose}) => {

    const {state,setState} = useContext(DistributionContext)
    const [options,setOptions] = useState("")
    const [selectedTable,setSelected] = useState("")
    const [transferValue,setTransfer] = useState(parseInt(row.cargo))

    const changeSelect = (e) => {
        const value = e.target.value
        setSelected(value)
        
    }

    const changeTransferValue = (e) => {
        const value = e.target.value

        if(value <= row.cargo){
            setTransfer(value)
        }

        if(value < 0){
            setTransfer(1)
        }

    }

    const transferRowIntermediate = () => {

        let checkValue = false
        let checkTable = false

        if(transferValue === 0){
            toast.error('Informe o valor a ser transferido !')
            return
        }else{
            checkValue = true
        }

        if(selectedTable === ""){
            toast.error('Selecione o caminhão de transferência !')
            return
        }else{
            checkTable = true
        }

        if(checkTable === true && checkValue === true){
            const consolidated = state.consolidatedStep4
            const tableName = `table${selectedTable}`

            const equation = row.cargo - transferValue
            
            transferRow(consolidated,tableName,table,row,equation,transferValue)
            
            setState({consolidatedStep4:consolidated})
            setState({changedTables:!state.changedTables})
        }

        setState({sendStep4:!state.sendStep4})
        handleClose()
    }

    const selectOptions = () => {
        
        let takeList = [ ]
        state.consolidatedStep4.forEach((each) => {
        let obj = Object.keys(each);
            takeList.push(obj[0])
        });

        let newList = takeList.filter(each => each !== table)
        let newDict = []
        for (const each of newList){
            newDict.push({"id":each.replace('table','')})
        }

        setOptions(newDict)
        selectAutoTruck(state.consolidatedStep4)
    }

    const getNextPosition = (position, table) => {
        if (table[position])
            return table[position] + 1
        return 1
    }

    const selectAutoTruck = (ids) => {
        const currentNum = table.replace('table','')
        const trucks = Array(ids.length).fill(0).map((each, index) => index)
        const maxId = getNextPosition(currentNum,trucks)

        setSelected(maxId)
    }

    useEffect(() => {
        selectOptions() 
    },[])

    return(
        <>
        <Box sx={{ minWidth: 40,display:'flex',flexDirection:'column',gap:'2rem' }}>

                <FormControl fullWidth sx={{gap:'3rem'}} size="small">

                    <Box>
                        <InputLabel id="demo-simple-select-label">Caminhões</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Caminhões"
                        value={selectedTable}
                        onChange={changeSelect}
                        sx={{width:'100%'}}
                        >
                        
                        {
                        
                            Object.entries(options).map(([key,value]) => (
                                <MenuItem key={value.id} value={value.id}>Caminhão {value.id}</MenuItem>
                            ))
                        }

                        </Select>
                    </Box>
                
                
                    <Box>
                        <TextField
                        fullWidth
                            label="Pneus"
                            size="small"
                            type="number"
                            value={transferValue}
                            onChange={changeTransferValue}
                            inputProps={{
                                step: 1, // The increment/decrement amount when using the up/down buttons
                                min: 0, // The minimum value
                                max: row.cargo, // The maximum value
                            }}
                            />
                    </Box>

                </FormControl>
          
                <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                    <ColorButton onClick={transferRowIntermediate} >Transferir</ColorButton>
                </Box>
            </Box>
         
        </>
    )
}
export default ModalTransfer;