import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import Table2, {TableHook} from '../../Table/indexSec';
import EditColaborador from './edit';
import hasPermissions from '../../Utils/groups';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { GridToolbar  } from '@mui/x-data-grid';

const url = '/colaboradores/'
const Permission = ('administrador')

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const hideCollaborator = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.patch(`${url}hide/${data.id}/`)
    setLoader(false)
    if (res.status === 200) {
        toast.success('Ocultado com sucesso')
        setState({ hideRow: false })
        getData()
        return
    }
    console.error('Falha ao ocultar')
    
}

export const loadData = async (setState, permission, setLoader) => {
    if (!permission) {
        toast.error('Você não tem permissão para ver os colaboradores')
        return
    }
    // const requests = [
    //     api.get(url + 'unitys/')
    // ]
    // const [unitys] = await Promise.all(requests)
    // const result = {}
    // if (unitys.status === 200)
    //     result.unitys = unitys.data
    // setState({ ...result })
}

const columns = (setState, permissions) => {
    return [
        { field: 'OID', headerName: 'OID', flex: 0.1, filterType: 'number' },
        { field: 'Usuario', headerName: 'Usuário', flex: 0.3, filterType: 'string' },
        { field: 'nome', headerName: 'Codvend', flex: 0.3, filterType: 'string' },
        { field: 'Grupo', headerName: 'Tipo', flex: 0.3, filterType: 'string' },
        { field: 'telefone', headerName: 'Telefone', flex: 0.3, filterType: 'string' },
        { field: 'email', headerName: 'Email', flex: 0.3, filterType: 'string' },
        { field: 'branches', headerName: 'Filial', flex: 0.3, filterType: 'string' },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <Box >
                        {hasPermissions(Permission) && <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>}
                        {hasPermissions(Permission) && <IconButton onClick={() => { setState({ hideRow: props.row }) }}><HideSourceIcon /></IconButton>}
                        {hasPermissions('block') && <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>}
                    </Box>
                )
            }
        }
    ]
}

const Colaboradores = () => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        hideRow: null,
        unitys: []
    })

    useEffect(() => {
        loadData(setState, hasPermissions(Permission), setLoader)
    }, [setLoader, hasPermissions(Permission)])

    const [table, reloadTable] = TableHook()

    const reloadData = () => {
        loadData(setState, hasPermissions(Permission), setLoader)
        reloadTable()
    }

    return(
        <>
        {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o colaborador ${state.deleteRow.name} - ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
        }

        {state.hideRow &&
                <ConfirmationDialog
                    content={`Deseja ocultar o colaborador ${state.hideRow.Usuario} ?`}
                    handleClose={() => setState({ hideRow: null })}
                    onConfirm={() => hideCollaborator(state.hideRow, setState, reloadData, setLoader)}
                />
        }

        {state.editRow &&
                <EditColaborador
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
        }

         <Box className='information-table'>
                <Box className='user-table'>
                    <Table2
                        rows={state.rows}
                        columns={useMemo(()=> columns(setState, Permission), [Permission])}
                        pagination
                        density='compact'
                        slots={{
                            
                             toolbar: GridToolbar 
                        }}
                        
                        getURL={url}
                        updateRows={(rows) => setState({'rows': rows})}
                        innerRef={table}
                    />
                </Box>
            </Box>
        </>
    )
}

export default Colaboradores;