import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useNavigate } from "react-router-dom";

const Submenu = ({config,theme}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const routeList = config.submenu.map(each => each.redirect)
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const navigate = useNavigate();

    return(
        <>
            {
                config.permissions ?
                <>
                    <Button
                    {...theme(routeList)}
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    children={<>{config.icon}<span>{config.title}</span></>}
                    >
                    </Button>
                    <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    >

                    {config.submenu.map((item, index) => (

                            
                    item.permissions ?
                        <MenuItem  onClick={() => {navigate(`/${item.redirect}`); handleClose()}} key={index}>{item.title}</MenuItem>
                    :<></>
                            
                    ))}
                    
                    </Menu>
               </>

                : <></>
          
            }
            
        </>
    )
}

export default Submenu;