import React from 'react'

import { InputAdornment } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew';

const LoadingIcon = ({loading}) => {
    return (
        <>
        {loading && 
        <InputAdornment position="end">
            <AutorenewIcon className='spinner' />
          </InputAdornment>}
        </>
    )
}

export default LoadingIcon