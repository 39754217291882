import React, { useState, useContext} from 'react';
import { Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { PainelContext } from './painel';

const Justifications = ({justifications,otherMessage,getCheckboxValue}) => {
    const {setState} = useContext(PainelContext)
    const [message,setMessage] = useState('')
    const changeMessage = (e) => {
        setMessage(e.target.value)
    }

    const blurMessage = () => {
        setState({message:message})
    }

    return(
        <FormGroup>
              {
              justifications.map((each, index) => (
                <FormControlLabel
                  key={index}
                  control={<Checkbox />}
                  label={each.option}
                  onClick={(e) => getCheckboxValue(e,each.id)}
                />
              ))
              }
              {
                 otherMessage ?
                 <Box>
                    <br/>
                    <TextField  
                    id="outlined-multiline-static" 
                    label="Justificativa" 
                    variant="outlined" 
                    fullWidth
                    onChange={changeMessage}
                    onBlur={blurMessage}
                    value={message}
                    inputProps={{
                        maxLength:5000
                    }} />
                </Box> 
                 :
                 <></>
              }
            </FormGroup>
    )
}

export default Justifications;