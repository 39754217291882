import { Button } from '@mui/material';

import apptheme from '../theme/default'
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme, fColor=apptheme.primaryColor, sColor=apptheme.secondaryColor, hColor=apptheme.tertiaryColor,reverse=false, border='none' }) => ({
    color: reverse ? theme.palette.getContrastText(sColor) : theme.palette.getContrastText(fColor),
    border: border,
    backgroundColor: reverse ? sColor : fColor,
    '&:hover': {
      backgroundColor: reverse ? (hColor? hColor: sColor) : (hColor? hColor: fColor),
      color: reverse ? theme.palette.getContrastText(hColor? hColor: sColor) : theme.palette.getContrastText(hColor? hColor: fColor)
    },
    ':disabled':{
      color: hColor,
    },
  }));


export default ColorButton