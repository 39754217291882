import React, { useContext, useState } from 'react'
import { DistributionContext } from '.';
import { Box } from '@mui/material';
import ColorButton from '../Buttons/ColorButton';
import toast from 'react-hot-toast';
import { api } from '../../services/api';
import { externalComponent } from '../AppRoutes';
import DefaultModal from '../Utils/DefaultModal';
import ConfirmStartStep from './confirmStart';
import ModalRepresentative from './modalRepresentative';
import { sortTables, formatDate } from './functionsCore';
import { distributionInitialState } from '.';


const step3TableData = async (state,setState,setLoader,param) => {

    //Essa funcao serve para separar as listas que tem mais de 1 codpro no mesmo aro medida

    const tableCargoA = state.distrA
    const tableCargoB = state.distrB
    const dtInicial = state.dtInicial
    const dtFinal = state.dtFinal
    const est = state.selectedFilial
    const ven = state.selectedFilialVen

    const outputListA = tableCargoA.filter(each => each.cargo > 0 );
    const outputListB = tableCargoB.filter(each => each.cargo > 0 );

    const consolidatedList = [...outputListA, ...outputListB]

    const aro = consolidatedList.map(each => each.aro)
    const medida = consolidatedList.map(each => each.medida)

    const body = {
        "dt_inicial": formatDate(dtInicial),
        "dt_final": formatDate(dtFinal),
        "aro": aro,
        "medida": medida,
        "est":est,
        "ven":ven
    }
  
    setLoader(true)
    const res = await api.post('/codpro/', body)
    if (res.status === 200){
        const data = res.data
        const finalListA = []
        const finalListB = []
        for (const dict of consolidatedList) {
            
                let aro = dict.aro
                let medida = dict.medida
                let aro_med = `${aro}-${medida}`

                let codpro = data.filter( each => `${each.aro}-${each.medida}` === aro_med)[0][aro_med]
             
                
                if(codpro.length > 1){
                    const new_dict = {...dict,'list':codpro,"id": dict.id, "aro_med":aro_med}
                    finalListA.push(new_dict)
                }else{
                    codpro = codpro[0]
                    const new_dict = {est:codpro[est], ven:codpro[ven],"aro":codpro.aro,"medida":codpro.medida,"dispbks":codpro.dispbks,
                    "descr":codpro.descr,"codpro":codpro.codpro, "id": dict.id, "peso":codpro.peso,"m3":codpro.m3,"cargo":dict.cargo}
                    finalListB.push(new_dict)
                }    
        }

        //aplica um id apenas pra conseguir fazer ficar colorido alternado de forma continua

        let conta = 0
        if(param === 'update'){
            
            for (const each of  finalListA){
                    const list = each.list
                    conta += list.length
                    for (const item of list){
                        let itemUpdate = state.consolidatedListA.filter(eachI => eachI.aro_med == each.aro_med )[0].list
                        let listUpdate = itemUpdate.filter(eachL => eachL.codpro == item.codpro)
                        let cargoUpdate = listUpdate[0]['cargo']
                        if(cargoUpdate === undefined){
                            cargoUpdate = 0
                        }
         
                        item['cargo'] = cargoUpdate
                        item['envio'] = cargoUpdate
 
                    }
            }
        }
        
       
        if(param === 'update'){
           
            setState({sendStep3:!state.sendStep3})
        }

        const newfinalListA = []
        finalListA.map(each => each.list.sort((a, b) => a.codpro > b.codpro ? 1 : -1));
        finalListA.map(each => newfinalListA.push(each))


        let id_codpro = 1
        for (let i = 0; i < newfinalListA.length; i++) {
            let list_codpro = newfinalListA[i]['list']

            for (const dict of list_codpro){
                dict['id'] = id_codpro
                if(param === 'consult'){
                    dict['envio'] = 0 
                }
               if(param === 'update'){
                dict['envio'] = dict.envio
               }
                id_codpro += 1
            }

        }

        if(param === 'update'){
           
            for(const each of newfinalListA){
                const cargoValues = each.list.reduce((acc, curr) => acc + curr.cargo, 0);
                const diferenca = parseInt(each.cargo) - parseInt(cargoValues)
                each['diferenca'] = diferenca
            }
        }


        const sorted_tableA = sortTables(newfinalListA)
        const sorted_tableB = sortTables(finalListB)

        setState({consolidatedListA:sorted_tableA,consolidatedListB:sorted_tableB})

    }
    setLoader(false)

}

const consolidatedStep4 = (state,setState) => {
    const result = state.consolidatedListA.flatMap((dict) =>
    dict.list.filter((each) => each.envio !== 0).map((each) => each)
    );                                                                                                                                      
   

    const new_list = [...result,...state.consolidatedListB]
    
    let idList = 1
    for(const each of new_list){
        each.id = idList
        idList = parseInt(idList) + 1
        each.checked = 'false'
    }

    const new_dict = [
            {   
                "table1":{
                    "truck_size":118,
                    "total_m3":"",                                                                                                                                                                                                                              
                    "lines":new_list
                }                                                                                               
            }
        ]

    setState({consolidatedStep4:new_dict})

}

const ButtonsModalExecute = () => {

    const {state,setState} = useContext(DistributionContext)
    const {setLoader} = useContext(externalComponent)
    const [modalStateStart,setModalStateStart] = useState(false)
    const [modalRepresentative,setModalRepresentative] = useState(false)
   
    const openModalStart = () => {
        setModalStateStart(true) 
    }

    const closeModalStart = () => {
        setModalStateStart(false) 
    }

    const openModalAffiliate = () => {
        const lenTables = state.consolidatedStep4.length
        
        let error = false
        for (let i = 1; i <= lenTables; i++) {
            
            let lines = state.consolidatedStep4.filter(each => each[`table${i}`])[0][`table${i}`]['lines']
            
            if(lines.length === 0){
                error = true
                
            }

        }

        if(error === true){
            
            toast.error('Todas os caminhões devem ter cargas para finalizar!')
        }else{
            setModalRepresentative(true) 
        }
        
    }

    const closeModalAffiliate = () => {
        setModalRepresentative(false) 
    }


   
    const nextStep = async () => {
        if(state.selectedFilial === ""){
            toast.error('Selecione uma Filial antes de avançar !')
        }
        else{
            const newStep = state.step + 1
            setState({step:newStep})

            if(newStep === 2){
                await step3TableData(state,setState,setLoader,'consult')
                setState({functionStep3:step3TableData}) 
            }

            if(newStep === 3){
                consolidatedStep4(state,setState)
            }
        }

       
    }

    
    const startStep = async () => {
        const newStep = 0
       
        const reset = JSON.parse(state.dataToResetDist)
        setState({...distributionInitialState,data:reset,dataToResetDist:state.dataToResetDist,branches:state.branches,step:newStep,modalState:true})
        closeModalStart()
      
    }

    const openModalStep2 = () => {
        setState({modalStep2:true})
    }
    
    const Buttons1 = () => {
        return(
            <Box>
                <ColorButton  className='realpadding' onClick={nextStep}>Proxímo</ColorButton>
            </Box>
        )
    }

    const Buttons2 = () => {
        return(
            <Box>
                <ColorButton style={{marginRight:'20px'}} className='realpadding' onClick={openModalStart}>Voltar ao início</ColorButton>

                <ColorButton  className='realpadding' onClick={nextStep}>Proxímo</ColorButton>
            </Box>
        )
    }

    const Buttons3 = () => {
        return(
            <Box>
                <ColorButton style={{marginRight:'20px'}} className='realpadding' onClick={openModalStart}>Voltar ao início</ColorButton>

                <ColorButton  className='realpadding' onClick={nextStep}>Proxímo</ColorButton>
            </Box>
        )
    }

    const Buttons4 = () => {
        return(
            <Box>
                <ColorButton style={{marginRight:'20px'}} className='realpadding' onClick={openModalStep2}>Consultar Estoques</ColorButton>

                <ColorButton style={{marginRight:'20px'}} className='realpadding' onClick={openModalStart}>Voltar ao início</ColorButton>

                <ColorButton  className='realpadding' onClick={openModalAffiliate}>Finalizar</ColorButton>
            </Box>
        )
    }

    const buttonConfirmStart = () => {
        return(
        <Box>
            <ColorButton onClick={startStep} >Sim </ColorButton>
        </Box>
        )
    }

  

    const steps = () => {
        const step = state.step

        switch(step) {
            case 0:
                return <Buttons1/>
            case 1:
                return <Buttons2/>
            case 2:
                return <Buttons3/>
            case 3:
                return <Buttons4/>
        }

    }
    return(
        <>
        <DefaultModal dialogProps={{maxWidth:"xs"}} open={modalStateStart} handleClose={closeModalStart} content={<ConfirmStartStep/>} 
            title={' Confirmação '} action={buttonConfirmStart()} />

    <DefaultModal dialogProps={{maxWidth:"sm"}} open={modalRepresentative} handleClose={closeModalAffiliate} content={<ModalRepresentative handleClose={closeModalAffiliate}/>} 
     title={' Representantes '} />

            {steps()}
        </>
    )
}



export default ButtonsModalExecute;
