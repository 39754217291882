import React, { useContext, useEffect } from 'react'
import { DistributionContext } from '.';
import Box from '@mui/material/Box';
import NumTruck from './numTruck';
import DatagridVisualization from './datagridVisualization';
import SearchCodpro from './SearchCodpro';
import { sortTables } from './functionsCore';

const Visualization = ({hash}) => {

    const {state,setState} = useContext(DistributionContext)

    const countTruckNumber = () => {
        const num_truck = state.consolidatedStep4.length
  
         setState({numTruck:num_truck})
      }
  
      useEffect(() => {
        countTruckNumber()
      },[])
      
      const changeList = (dict) => {
        let lines = state.consolidatedStep4[0]['table1']['lines']
        state.consolidatedStep4[0]['table1']['lines'] = [...lines,dict]
        setState({consolidatedStep4:state.consolidatedStep4})
        setState({changedTables:!state.changedTables})
    }

    const checkTable = (dict) => {
        let lines = state.consolidatedStep4[0]['table1']['lines']
        const check = lines.filter(each => each.codpro === dict['codpro'])
        if(JSON.stringify(check) === '[]'){
            return false
        }else{
            return true
        }
    }
   

      return(
        <>
        <Box>
          <SearchCodpro changeList={changeList} checkTable={checkTable} state={state} setState={setState} type={'admin'} data={state.consolidatedStep4} />
          <br/><br/><br/>
          <NumTruck />
        </Box>
        
        {
          state.consolidatedStep4.map((item, index) => {
            const num = index + 1;
            const keyTB = `table${num}`
            const current = item[keyTB]

            let num_m3 = 0
            let total_cargo = 0
            let total_peso = 0
            let num_truck = 0
            for(const each of current.lines){
              num_truck = parseInt(num_truck) + 1
              let convertedNumM3 = parseFloat(each.m3.replace(',','.')) * parseInt(each.cargo)
              let convertedNumPeso = parseFloat(each.peso) * parseInt(each.cargo)

              num_m3 = convertedNumM3 + num_m3
              total_peso = convertedNumPeso+ total_peso
              total_cargo = parseInt(total_cargo) + parseInt(each.cargo)


            }
            num_m3 = num_m3.toFixed(2)
            total_peso = total_peso.toFixed(2)
            const truck_size = item[keyTB]['truck_size']
            item[keyTB]['total_m3'] = num_m3

            const linesTruck = current.lines
            const sorted_lines = sortTables(linesTruck)

            return (
           
                <DatagridVisualization index={num} hash={hash} truck_size={truck_size} lines={sorted_lines} table={keyTB} m3={num_m3} peso={total_peso} envio={total_cargo}/>
            
            );
          })
        }

        </>
      )

}

export default Visualization;