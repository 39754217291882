import React, { useState, useContext,useEffect } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import { DistributionContext } from '.';
import { toast } from 'react-hot-toast'

const SendBox = ({codpro,aro,med,envio,cargo,listFull,row}) => {
   
    const {state,setState} = useContext(DistributionContext)

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(envio)

    const aro_med = `${aro}-${med}`;

    const changeToInput = () => {
        setIcon(false)
    }

    const isNumber = (value) => { 
        return !isNaN(value - 0) && isFinite(value); 
    }

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const check_num = isNumber(inputValue)

        if(check_num){
            setSend(inputValue)

            // if(state.validateStep3){
            //     let check = false
            //     for (const dict of state.validateStep3){
                    
            //         if(dict['aro'] === aro && dict['medida'] === med && dict['codpro'] === codpro){
            //             dict['envio'] = parseInt(inputValue)
            //             check = true
            //         }
            //     }

            //     if(check === false){
            //         const new_dict = {"aro":aro,"medida":med,"codpro":codpro,"envio":inputValue}
            //         state.validateStep3.push(new_dict)
            //     }
                
            // }else{
            //     const new_dict = [{"aro":aro,"medida":med,"codpro":codpro,"envio":inputValue}]
            //     setState({validateStep3:new_dict})
            // }

            // let envio = 0
            // for (const dict of state.validateStep3){
            //     if(dict['aro'] === aro && dict['medida'] === med && dict['codpro'] !== codpro){
            //         envio = parseInt(envio) + parseInt(dict['envio'])
            //     }
            // }

            // let diff = parseInt(envio) + parseInt(inputValue)

            // if(diff > cargo){

            //     let correct = parseInt(cargo) - parseInt(envio)

            //     toast.error('Número de envio excedendo o número à enviar !')
            //     for (const dict of state.validateStep3){
                    
            //         if(dict['aro'] === aro && dict['medida'] === med && dict['codpro'] === codpro){
            //             dict['envio'] = parseInt(correct)
            //         }
            //     }
            //     setSend(correct)
            // }
            

        }
      };

      const changeDict = () => {
        for (const dict_out of state.consolidatedListA){
            if(dict_out['aro_med'] === aro_med){
                for(const dict of dict_out['list']){
                    if(dict['codpro'] === codpro){
                        if(send === ""){
                            dict['envio'] = 0
                            setSend(0)
                        }else{
                            dict['envio'] = parseInt(send)
                            dict['cargo'] = parseInt(send)
                        }
                        
                        
                    }
                }
            }
        }
        
        setIcon(true)
        changeDifference()
      }

      const changeSend = () => {
        let cargo = listFull.cargo

        if(cargo === "" || cargo === undefined){
            cargo = 0
        }
        setSend(cargo)
        setState({consolidatedListA:state.consolidatedListA})
      }

      const changeDifference = () => {
        let valueSend = 0
        if(send === ""){
            setSend(0)
            valueSend = 0
        }else{
            valueSend = send
        }
        const listRows = row.list.filter(each => each.codpro != codpro)
        const allValues = listRows.reduce((acc, curr) => acc + curr.envio, 0);
        const difference = parseInt(cargo) - (parseInt(valueSend) + parseInt(allValues))
        row.diferenca = difference
      }


      useEffect(() => {
       
        changeDifference()
        changeSend()
    },[state.sendStep3])

    return(
        <>
        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon'>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true}  value={send} onChange={handleChange} onBlur={changeDict} type="text"/>
            </Box>
        }   
        </>
    )
}

export default SendBox;