import React, { useContext, useLayoutEffect, useReducer, useRef } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { lockedReducer } from '../Utils/reducers'
import { api } from '../../services/api'
import { externalComponent } from '../AppRoutes'
import TableToolbar from './toolbar'
import MobileTable from './mobileTable'

const getData = async (url, state, updateRows = () => { }, setLoader, setState, rows = []) => {
    setLoader(true)


    const searchParams = new URLSearchParams();

    //pagination
    searchParams.set('limit', state.paginationModel.pageSize)
    searchParams.set('offset', (state.paginationModel.page) * state.paginationModel.pageSize)

    if (state.mobileFilter)
        searchParams.set('offset', 0)

    //filter
    Object.entries(state.filterModel.customItems).forEach(([key, value]) => {
        value && searchParams.set([key.replace('__equals', '')], value)
    })

    //or
    if (state.filterModel.or)
        searchParams.set('or', 'or')


    setLoader(false)
    const newState = {
        paginationModel: state.paginationModel,
        filterModel: { ...state.filterModel }
    }
    newState.ready = true
    setState(newState)
}

export const TableHook = () => {
    const table = useRef()
    const reloadData = table.reloadData || (() => { })
    return [table, reloadData]
}

const initState = {
    paginationModel: { page: 0, pageSize: 25 },
    filterModel: { items: [], customItems: {} },
    next: null,
    rowCount: 0,
    previous: null,
    ready: true,
}

let lastRender = false

const Table = ({ getURL, updateRows, innerRef, toolbar, mobile, mobileProps,...props}) => {

    const { loader, setLoader, isMobile } = useContext(externalComponent)

    const apiRef = useRef()

    const [state, setState] = useReducer(lockedReducer, {
        ...initState,
        getURL: getURL
    })


    

    if (innerRef) {
        innerRef.reloadData = (url, data = {}) => { getData(url || getURL, { ...state, ...data }, updateRows, setLoader, setState) }
        innerRef.apiRef = apiRef
        innerRef.state = state
    }

    const settoolbar = toolbar ? {
        slots: { ...props.slots, toolbar: TableToolbar }
    } : null

    return (
        <>
            {(state.ready && (!isMobile || !mobile)) &&
                <DataGrid
                    {...props}
                    {...settoolbar}
                    pageSizeOptions={[25, 50, 100]}
                    apiRef={apiRef}                 
                />}
                {(state.ready && (isMobile && mobile)) &&
                <MobileTable 
                    rows={props.rows}
                    parent={state}
                    setParent={setState}
                    onFilterModelChange={(e) => getData(state.getURL, { ...state, filterModel: e, mobileFilter:true }, updateRows, setLoader, setState)}
                    onPaginationModelChange={(e) => {
                        getData(state.getURL, { ...state, paginationModel: e, scroll: true }, updateRows, setLoader, setState, props.rows)
                    }}
                    columns={props.columns}
                    slots={props.slots}
                    getRowClassName={props.getRowClassName}
                    {...mobileProps}
                />}
        </>
    )
}

export default Table