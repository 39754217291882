export const mergePaginatedData = (oldData, newData, callBack) => {
    oldData.next = newData.next
    oldData.count = newData.count
    oldData.results = mergeList(oldData.results, newData.results)
}

const mergeList = (oldFaq, newFaq, identifier='id') => {
    const oldFaqObject = oldFaq ? Object.fromEntries(oldFaq.map(each => [each[identifier], each])) : {}
    const newFaqObject = Object.fromEntries(newFaq.map(each => [each[identifier], each]))
    const returnlist = Object.values({...oldFaqObject, ...newFaqObject}).sort((a, b)=> b[identifier]- a[identifier])
    console.log(returnlist)
    return returnlist
}


export default mergeList