import React, { useContext } from 'react'
import DefaultModal from '../Utils/DefaultModal';
import { DistributionContext } from '.';
import BodyModalOrdes from './bodyModalOrders';

const ModalOrders = () => {

    const {state,setState} = useContext(DistributionContext)
    
    const closeModal = () => {
        setState({modalOrders:false})
    }
      
    return(
        <>
          <DefaultModal dialogProps={{maxWidth:'xm'}} open={state.modalOrders} handleClose={closeModal} content={<BodyModalOrdes/>}
            title={'Finalizados'}/>
        </>
    )
}

export default ModalOrders;