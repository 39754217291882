import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import { DistributionContext } from '.';

const SendBoxStep4 = ({codpro,cargo,table}) => {
    
    const {state,setState} = useContext(DistributionContext)

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(cargo)


    const changeToInput = () => {
        setIcon(false)
    }

    const isNumber = (value) => {
        return !isNaN(value - 0) && isFinite(value);
    }

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const check_num = isNumber(inputValue)
        if(check_num){
            setSend(inputValue)
        }
    }

    const changeDict = () => {
   
        const lines = state.consolidatedStep4.filter(each => each[table])[0][table]['lines']

        for (const each of lines){
           
            if(each['codpro'] === codpro){
                if((send) === "" || parseInt(send) === 0){
                    each['cargo'] = 1
                    setSend(1)
                }else{
                    each['cargo'] = parseInt(send)
                }
            }
        }
        
        setState({consolidatedStep4:state.consolidatedStep4})
        setIcon(true)
      }

      const getUpdatedSend = () => {
    
        for (const each of state.consolidatedStep4){
            if(each[table]){
                try {
                    const lines = each[table]['lines']
                    const cargo_envio = lines.filter(each => each.codpro === codpro)[0]['cargo']
                    setSend(cargo_envio)
                  } catch (error) {
                    
                  }
            }
        }
    }

      useEffect (() => {
        getUpdatedSend()
      },[state.sendStep4])

return(
<>
        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon'>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true} value={send} onChange={handleChange} onBlur={changeDict} type="text"/>
            </Box>
        }   
</>
        
)
}

export default SendBoxStep4;