import React, { useState } from 'react'
import Link from '@mui/material/Link';

const Footer = () => {

    const link = process.env.REACT_APP_URL_PLATESYS

    return(
        <section>
                <div className="footer text-center">
                    <Link href={link} underline="none" color="inherit">
                        <h4>PlateSys ®</h4>
                    </Link>
                </div>      
        </section>
    );
}

export default Footer;