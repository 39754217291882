import React from 'react'
import { PatternFormat } from 'react-number-format';

export const PhoneInput = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const {onChange, ...other } = props;

    return (
        <PatternFormat 
            {...other}
            getInputRef={ref}
            format="## (##) #####-####" 
            allowEmptyFormatting
            onValueChange={(values) => {
                onChange({
                  target: {
                    name: props.name,
                    value: values.value,
                  },
                });
              }}
        />
    )
}
)


export const CepInput = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const {onChange, ...other } = props;

  return (
      <PatternFormat 
          {...other}
          getInputRef={ref}
          format="#####-###" 
          onValueChange={(values) => {
              onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }}
      />
  )
}
)

