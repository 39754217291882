import React,{useState,useContext} from 'react'
import { DistributionContext } from '.';
import Box from '@mui/material/Box';

const BoxSizeTruck = ({title,table,truck_size,colorBack,color}) => {

    const {state} = useContext(DistributionContext)
    const [value,setValue] = useState(truck_size)


    const alterNumDict = (num) => {
        for ( const tables of state.consolidatedStep4){
            if(tables[table]){
                tables[table]['truck_size'] = num
            }
            
        }
    }

    const changeValue = (e) => {
        const value_num = e.target.value
        setValue(value_num)
        alterNumDict(value_num)
    }

    return(
    <>
    <Box sx={{display:'flex',flexDirection:'column',width:'9rem'}}>
            <Box sx={{border:'1px solid #ced4da',padding:'0.5rem', fontWeigh:'500', fontSize:'11.5px', textAlign:'center', backgroundColor: colorBack ? colorBack : '#ced4da', color: color ? color : 'black' }}>
                {title}
            </Box>

            <Box>
                <input className='inputSizeNum' type='number' value={value} onChange={changeValue} />
            </Box>
    </Box>
    </>
    )

}

export default BoxSizeTruck;