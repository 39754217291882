import React from 'react';
import Box from '@mui/material/Box';

const InformativeNotice = ({pedido,user}) => {
    return(
        <>
            <Box sx={{display:'flex', justifyContent:'center',padding:'15rem',fontSize:'2rem'}}>
                {
                    pedido ?
                    <p>O(s) pedido(s) ja foram criado(s), pelo usuário {user}, número(s): {pedido}</p>
                        
                    :
                    <p>O pedido foi cancelado.</p>
                    
                }
            </Box>
        </>
    )
}

export default InformativeNotice;