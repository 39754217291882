import React, { useState, useContext, useEffect } from 'react'
import { DistributionContext } from '.';
import Box from '@mui/material/Box';
import { stringToInt } from './functionsCore';

const CargoBoxModalStep2 = ({aro,med}) => {
    
    const {state} = useContext(DistributionContext)
    const [cargo,setCargo] = useState(0)


    const calculateCargoValue = () => {
        const lenTables = state.consolidatedStep4.length
        let allCargoSum = 0
        for (let i = 1; i <= lenTables; i++) {
            
            let lines = state.consolidatedStep4.filter(each => each[`table${i}`])[0][`table${i}`]['lines']
            let cargos = lines.filter(each => each['aro'] === aro && each['medida'] === med)
            let cargoSum = cargos.reduce((accumulator, currentValue) => accumulator + stringToInt(currentValue.cargo), 0);
            allCargoSum += cargoSum
            
            
        }
        setCargo(stringToInt(allCargoSum))
    }

    useEffect(() => {
        calculateCargoValue()
    },[])

    return(
        <>
        <Box sx={{padding:'0 37px',fontWeight:'bold'}}>
          {cargo}
        </Box>
        </>
    )
}

export default CargoBoxModalStep2;