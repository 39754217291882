import React, { useContext, useEffect, useState } from 'react'
import { DistributionContext } from '.';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import SendBox from './sendBoxStep2';
import BoxInfo from './boxInfo';
import Typography from '@mui/material/Typography';


const Step2 = () => {

    const {state,setState} = useContext(DistributionContext)
    const [datagrid1,setDatagrid1] = useState({})
    const [datagrid2,setDatagrid2] = useState({})

    const columns = [
    
        {
            field: 'aro',
            headerName: 'Aro',
            flex: 0.7,
            cellClassName: () => {
                return clsx('default-app bold', {
                    selected: true ,
                });
            }
        },
        {
            field: 'medida',
            headerName: 'Medida',
            flex: 0.9,
            cellClassName: () => {
                return clsx('default-app bold align-start', {
                    selected: true ,
                });
            }
        },
        {
            field: 'estba',
            headerName: 'Est BA',
            flex: 0.5,
            cellClassName: (params) => {
            if (params.field === state.selectedFilial) {
                return clsx('super-app est-tables', {
                    selected: true ,
                });
            }else{
                return clsx('default-app est-tables', {
                    selected: true ,
                });
            }
          }
        },
        {
            field: 'estce',
            headerName: 'Est CE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estmt',
            headerName: 'Est MT',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estpe',
            headerName: 'Est PE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estse',
            headerName: 'Est SE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estpb',
            headerName: 'Est PB',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estrn',
            headerName: 'Est RN',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'estpa',
            headerName: 'Est PA',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilial) {
                    return clsx('super-app est-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app est-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venba',
            headerName: 'Ven BA',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'vence',
            headerName: 'Ven CE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venmt',
            headerName: 'Ven MT',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venpe',
            headerName: 'Ven PE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'vense',
            headerName: 'Ven SE',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venpb',
            headerName: 'Ven PB',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venrn',
            headerName: 'Ven RN',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'venpa',
            headerName: 'Ven PA',
            flex: 0.5,
            cellClassName: (params) => {
                if (params.field === state.selectedFilialVen) {
                    return clsx('super-app ven-tables', {
                        selected: true ,
                    });
                }else{
                    return clsx('default-app ven-tables', {
                        selected: true ,
                    });
                }
              }
        },
        {
            field: 'dispbks',
            headerName: 'BKS Disp',
            flex: 1,
            cellClassName: () => {
                return clsx('default-app dep-bks', {
                    selected: true ,
                });
            }
        },
        {
            field: state.reservBranch,
            headerName: state.reservBranchName,
            flex: 1,
            cellClassName: () => {
                return clsx('default-app reserv', {
                    selected: true ,
                });
            }
        },
        {
            field: 'cargo',
            headerName: 'Carga',
            flex: 1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => (
                <Box sx={{display:'flex',flexDirection:'column', width:'100%'}}>
                   <SendBox aro={params.row.aro} med={params.row.medida} envio={params.row.cargo} type={params.row.type}
                    cargo={params.row} calculateTotal={calculateTotal}
                   />
                </Box>
              ) 
        }

    ]

    const calculateTotal = () => {
        const totalA = state.distrA.reduce((sum, obj) => sum + (obj?.cargo || 0), 0);
        const totalB = state.distrB.reduce((sum, obj) => sum + (obj?.cargo || 0), 0);
        const totalAB = totalA + totalB
    
        setState({totalAB:totalAB})
    }

    const changeDatagrid1 = (params) => {
        setDatagrid1(params)
        const stringColumn = JSON.stringify(params)
        localStorage.setItem('datagrid-step2-1', stringColumn );
    };

    const changeDatagrid2 = (params) => {
        setDatagrid2(params)
        const stringColumn = JSON.stringify(params)
        localStorage.setItem('datagrid-step2-2', stringColumn );
    };

    useEffect(() => {
        
        const datagrid1 = localStorage.getItem('datagrid-step2-1');
        if (datagrid1) {
            setDatagrid1(JSON.parse(datagrid1));
        }

        const datagrid2 = localStorage.getItem('datagrid-step2-2');
        if (datagrid2) {
            setDatagrid2(JSON.parse(datagrid2));
        }
    },[])

    return (
        <>
        <Box sx={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>

            <Box sx={{display:'flex',flexDirection:'column', gap:'2rem'}}>
                <BoxInfo title={'TOTAL DE PNEUS'} value={state.totalAB} colorBack={'#c60000'} color={'white'} />

                <Typography variant="h6" sx={{color:'#c60000'}}>Itens que necessitam de maior atenção</Typography>
            </Box>

            <Box sx={{width:'100%'}} >

                <br/>
                <DataGrid
                        rowHeight={state.rowHeight}
                        className='alterned_grid'
                        density='compact'
                        rows={state.distrA}
                        columns={columns}
                        columnVisibilityModel={datagrid1}
                        onColumnVisibilityModelChange={(newModel) =>
                            changeDatagrid1(newModel)
                        }
                        sx={{
                            '& .super-app.selected': {
                                fontWeight: '600',
                                justifyContent:'center',
                                alignItems:'center',
                            },
                            '& .default-app.selected': {
                                justifyContent:'center',
                                alignItems:'center',
                            },
                            maxHeight:"30rem",
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                justifyContent: 'center',
                            },
                                
                        }}
                        
                    /> 
            </Box>

                 
            <Box>
                <br/><br/>    
                <Typography variant="h6" sx={{color:'#c60000'}}>Itens calculados automaticamente</Typography>
            </Box>

            <Box sx={{width:'100%'}} >
                    <br/>
                    <DataGrid
                        rowHeight={state.rowHeight}
                        className='alterned_grid'
                        density='compact'
                        rows={state.distrB}
                        columns={columns}
                        columnVisibilityModel={datagrid2}
                        onColumnVisibilityModelChange={(newModel) =>
                            changeDatagrid2(newModel)
                        }
                        sx={{
                            '& .super-app.selected': {
                                fontWeight: '600',
                                justifyContent:'center',
                                alignItems:'center',
                            },
                            '& .default-app.selected': {
                                justifyContent:'center',
                                alignItems:'center',
                            },
                            maxHeight:"20rem",
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                justifyContent: 'center',
                            },
                        }}
                        
                    /> 
            </Box>

        </Box>

        </>
    )
}

export default Step2;