import React, { useContext } from 'react'
import DefaultModal from '../Utils/DefaultModal';
import { DistributionContext } from '.';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import { distributionInitialState } from '.';
import ButtonsModalExecute from './buttons';

//Tamanho da modal seguindo o num do case
const mapper = {
    0:"xs",
    1:"xl",
    2:"xl",
    3:"xl"
}

const ExecuteModal = () => {

    const {state,setState} = useContext(DistributionContext)

    const closeModal = async () => {
        const newStep = 0
      
        const reset = JSON.parse(state.dataToResetDist)
        setState({...distributionInitialState,data:reset,dataToResetDist:state.dataToResetDist,branches:state.branches,step:newStep,modalState:false})

  
    }
    
    const steps = () => {
        const step = state.step

        switch(step) {
            case 0: 
                return <Step1/>
            case 1:
                return <Step2/>
            case 2:
                return <Step3/>
            case 3:
                return <Step4/>
        }

    }

    const titleSteps = () => {
        const step = parseInt(state.step) + 1
        
       
        const branchName = Object.entries(state.branches) .filter(([_, value]) => value.id === state.selectedFilial).map(([key, value]) => value.nome)[0]

        let title = ""
        if(step === 1){
            title = `Carga (${step})`
        }else{
            title = `Carga - ${branchName} (${step})`
        }
        return title
    }

    return(
        <>  
            <DefaultModal dialogProps={{maxWidth:mapper[state.step]}} open={state.modalState} handleClose={closeModal} content={steps()} action={<ButtonsModalExecute/>}
            title={titleSteps()}/>
        </>
    )

}

export default ExecuteModal;