
import React, { useState, useContext, createContext, useLayoutEffect, useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./styles.css";

import LoginComponent from "./Login/login";
import MenuComponent from "./Menu/menu";

import { Box } from "@mui/material";

import { AuthProvider, AuthContext } from "../contexts/auth";

import Home from "./Home/home";
import Loader from "./Loader/loader";

import Settings from "./Settings";
import Distribution from "./Distribuicao";
import Painel from "./Pedido/painel";
import NovoPedido from "./Pedido/novopedido";
import DistributionR from "./Distribuicao/distributionR";
import Cadastros from "./Cadastros";
import Consultas from "./Consultas/Consultas";
import { Toaster } from 'react-hot-toast'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR as datagridptBR } from '@mui/x-data-grid';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';

export const mobileWidth = '768'

export const externalComponent = createContext()

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  datagridptBR,
);

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}



const PrivateRoute = ({ botmenu, permission, component }) => {
  const [width] = useWindowSize();
  const { authenticated, loading } = useContext(AuthContext);
  const [loader, setLoader] = useState(false)
  const isMobile = width <= mobileWidth

  if (loading) {
    return <Loader external show={true} />;
    
  }

  if (!authenticated) {
    
    const url = window.location.href;
    const myUrl = new URL(url);
    const hashValue = myUrl.searchParams.get('hash');

    if(hashValue){
      sessionStorage.setItem('hash', hashValue);
    }
    return <Navigate to="/login" />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <externalComponent.Provider value={{ setLoader, width, isMobile }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptBR}
          localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <Loader external show={loader} />
          <Box className="main-screen">
            <Box sx={{ flex: 9, overflow: 'hidden' }}>{component &&
              <Box className={'default_padding'}>
                {width <= mobileWidth && <Box sx={{height:'3rem'}}></Box>}
                {component}
              </Box>
            }
            </Box>

            < >{botmenu && <MenuComponent />}</>
          </Box>
          </LocalizationProvider>
        </externalComponent.Provider>
      </ThemeProvider>
    </>

  );
};



const AppRoutes = () => {
 
  const [hash,setHash] = useState('')
  const getHash = () => {

    const url = window.location.href;
    const myUrl = new URL(url);
    const hashValue = myUrl.searchParams.get('hash');
  
    setHash(hashValue)
  }

    useEffect(() => {
      getHash()
  },[])

  return (

    <Router basename='/sistema'>
      <Toaster position='bottom-center' />
      <AuthProvider>
        <Routes>
          <Route exact path="/login" element={<LoginComponent />} />
          <Route
            exact
            path="/home"
            element={
              <PrivateRoute botmenu permission={true} component={<Home />} />
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Settings />}
              />
            }
          />
          <Route
            exact
            path="/distribuicao"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Distribution />}
              />

            }
          />
          <Route
            exact
            path="/painel"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Painel />}
              />

            }
          />

          <Route
            exact
            path="/novopedido"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<NovoPedido />}
              />

            }
          />

        <Route
            exact
            path="/"
            element={
              <PrivateRoute children={
                <Navigate to="/home"/>
              }/>
               
            }
          />

      <Route
            exact
            path="/distribuicao/r"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<DistributionR hash={hash} />}
              />

            }
          />

      <Route
            exact
            path="/cadastros"
            element={
              <PrivateRoute
                botmenu
                permission={
                  true
                }
                component={<Cadastros />}
              />

            }
          />

        <Route
            exact
            path="/consultas"
            element={
              <PrivateRoute
                botmenu
                permission={
                  true
                }
                component={<Consultas />}
              />

            }
          />
        
        </Routes>
        

      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
